import React from "react";
import styles from "./ErrorHandler.module.scss";
import { ErrorOption } from "react-hook-form";
import { Message } from "react-hook-form/dist/types/form";
import { LiteralUnion } from "react-hook-form/dist/types/utils";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

type ErrorHandlerProps = {
  errors: {
    message?: Message;
    type?: LiteralUnion<keyof RegisterOptions, string>;
    shouldFocus?: boolean;
  };
};

const ErrorHandler = ({ errors }: ErrorHandlerProps) => {
  return (
    <div className="exclamation">
      {errors && (
        <>
          {(() => {
            switch (errors) {
              case errors.type === "required":
                return (
                  <span className={`d-block ${styles.errorText} error-element`}>
                    {errors.message ? errors.message : "This field is required"}
                  </span>
                );
              case errors.type === "maxLength" || errors.type === "max":
                return (
                  <span className={`d-block ${styles.errorText} error-element`}>
                    {errors.message
                      ? errors.message
                      : "Your input exceeded the maximum length"}
                  </span>
                );
              case errors.type === "minLength" || errors.type === "min":
                return (
                  <span className={`d-block ${styles.errorText} error-element`}>
                    {errors.message
                      ? errors.message
                      : "Your input is not up to the minimum length"}
                  </span>
                );
              case errors.type === "matches":
                return (
                  <span className={`d-block ${styles.errorText} error-element`}>
                    {errors.message}
                  </span>
                );
              case errors.type === "typeError":
                return (
                  <span className={`d-block ${styles.errorText} error-element`}>
                    Invalid value entered
                  </span>
                );
              default:
                return (
                  <span className={`d-block ${styles.errorText} error-element`}>
                    {errors.message
                      ? errors.message
                      : "The value entered into this field is invalid"}
                  </span>
                );
            }
          })()}
        </>
      )}
    </div>
  );
};

export default ErrorHandler;
