import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import user from "../../../../assets/profile/user.svg";
import card from "../../../../assets/profile/card.svg";
import pin from "../../../../assets/profile/pin.svg";
import {
  FaUserAlt,
  IoMdArrowRoundBack,
  MdArrowForwardIos,
} from "react-icons/all";
import { ROUTES } from "../../../../helpers/ROUTES";
import { deviceType } from "react-device-detect";
import styles from "../../WidgetFlow.module.scss";
import { appInsights } from "../../../AppInsight/AppInsight";
import { CurrencyProp } from "../types";

type ProfileProps = {
  first_name: string;
  last_name: string;
  currency: CurrencyProp;
};
const ProfileComponent = ({
  first_name,
  last_name,
  currency,
}: ProfileProps) => {
  const history = useHistory();
  useEffect(() => {
    appInsights.trackPageView({
      name: "Profile.component.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <div
        onClick={() => history.push(ROUTES.widgetDashboard)}
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Profile</h4>
      <div className="d-flex justify-content-center cursor">
        <div
          className="position-relative rounded-circle d-flex justify-content-center align-items-center"
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
            backgroundColor: "#8080808c",
          }}
        >
          <FaUserAlt size="50px" className="text-white" />
        </div>
      </div>
      <br />
      <p className="font-weight-bold text-center text-primary">
        {`${first_name}  ${last_name}`}
      </p>
      <br />
      <div
        className="card cursor"
        onClick={() => history.push(ROUTES.widgetProfileInfo)}
      >
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              className={`${
                deviceType === "mobile" ? styles.iconMobile : styles.icon
              } icon__light__blue mr-3`}
            >
              <img
                src={user}
                alt=""
                className="img-fluid"
                style={{ width: "11.48px", height: "13.2px" }}
              />
            </div>
            <div>
              <p className="font-weight-bold text-dark padding-none">
                Profile Information
              </p>
              <small className="text-muted padding-none">
                Edit your profile information
              </small>
            </div>
          </div>

          <MdArrowForwardIos />
        </div>
      </div>
      {currency === "NGN" ? (
        <>
          <br />
          <div
            className="card cursor"
            onClick={() => history.push(ROUTES.widgetCard)}
          >
            <div className="card-body d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className={`${
                    deviceType === "mobile" ? styles.iconMobile : styles.icon
                  } icon__light__blue mr-3`}
                >
                  <img
                    src={card}
                    alt=""
                    className="img-fluid"
                    style={{ width: "11.48px", height: "13.2px" }}
                  />
                </div>
                <div>
                  <p className="font-weight-bold text-dark padding-none">
                    Card information
                  </p>
                  <small className="text-muted padding-none">
                    Update your card information
                  </small>
                </div>
              </div>

              <MdArrowForwardIos />
            </div>
          </div>

          <br />
          <div
            className="card cursor"
            onClick={() => history.push(ROUTES.widgetPin)}
          >
            <div className="card-body d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className={`${
                    deviceType === "mobile" ? styles.iconMobile : styles.icon
                  } icon__light__blue mr-3`}
                >
                  <img
                    src={pin}
                    alt=""
                    className="img-fluid"
                    style={{ width: "11.48px", height: "13.2px" }}
                  />
                </div>
                <div>
                  <p className="font-weight-bold text-dark padding-none">
                    Transaction PIN
                  </p>
                  <small className="text-muted padding-none">
                    Set your transaction PIN
                  </small>
                </div>
              </div>

              <MdArrowForwardIos />
            </div>
          </div>
        </>
      ) : null}
      <br />
      <br />
    </div>
  );
};

export default ProfileComponent;
