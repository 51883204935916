import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/all";
import { formatCurrency } from "../../../../../../helpers/formatCurrency";
import Loader from "../../../../../Loader/Loader";
import NewLoader from "../../../components/NewLoader";
import { SuccessComponent } from "../../../SuccessComponent";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { UserProp, WalletProp } from "../../../Dashboard/Dashboard.component";
import {
  getDataWithKey,
  postDataWithKey,
} from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { message } from "antd";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import { appInsights } from "../../../../../AppInsight/AppInsight";

const LoanRepaymentViaWallet = ({ wallet }: UserProp) => {
  let location = useLocation<any>();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [walletDetails, setWalletDetails] = useState<WalletProp | null>();

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRepaymentViaWallet.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function handleConfirmRepayment() {
    setIsLoading(true);
    try {
      await postDataWithKey(
        apiEndpoints.makeRepayment,
        {
          aggregator_id: wallet.aggregator_id,
          borrower_id: wallet.customer_id,
          borrower_repayment_mode: "Wallet",
          loan_id: location?.state?.loan_id,
          amount: Number(location?.state?.amount),
        },
        wallet.encryptedKey
      );
      setDone(true);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepaymentViaWallet },
      });
    }
    setIsLoading(false);
  }

  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${wallet.customer_id}&aggregator_id=${wallet.aggregator_id}`,
        wallet.encryptedKey
      );
      setWalletDetails(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepaymentViaWallet },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (wallet.customer_id && wallet.aggregator_id) {
      fetchWallet();
    }
  }, [wallet]);

  if (isLoading) {
    return <NewLoader />;
  }
  if (done) {
    return (
      <SuccessComponent
        btnText="Done"
        body="Your loan repayment is being processed"
        title="Repayment Successful!"
        handler={() => history.push(ROUTES.widgetLoan)}
        type="success"
      />
    );
  }
  return (
    <div>
      <div onClick={() => history.goBack()} className="cursor">
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Pay with wallet</h4>
      <p className="padding-none">
        You are about to make your loan repayment of{" "}
        <span className="font-weight-bold">
          {formatCurrency(location?.state?.amount || 0)}{" "}
        </span>
        using your Advancly Wallet
      </p>
      <br />
      <div className="card" style={{ backgroundColor: "#F2F5F9" }}>
        <div className="card-body p-3">
          <p className="text-muted padding-none" style={{ color: "#8B8699" }}>
            Wallet Balance
          </p>
          <div className="d-flex justify-content-between align-items-center">
            <p className=" font-weight-bold text-dark padding-none">
              {formatCurrency(walletDetails?.availableBalance || 0)}
            </p>

            <small className="  text-dark padding-none">
              Fee:
              {/*<span>&#8358;</span> */}
              {formatCurrency(0)}
            </small>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end" style={{ marginTop: "10%" }}>
        <div className="d-flex align-items-center">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={() => history.goBack()}
          >
            Go Back
          </p>
          <button
            className="btn btn-primary btn-radius"
            onClick={handleConfirmRepayment}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoanRepaymentViaWallet;
