import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../helpers/ROUTES";
import { postData } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";
import { errorHandler } from "../helpers/errorHandler";
import ResetPasswordStepOne from "./ResetPassword/ResetPasswordStepOne.component";
import ResetPasswordStepTwo from "./ResetPassword/ResetPasswordStepTwo.component";
import ResetPasswordStepThree from "./ResetPassword/ResetPasswordStepThree.component";

const ResetPassword = (props) => {
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseFromApi, setResponseFromApi] = useState(null);

  const onSubmitStepOne = async (data) => {
    const { email } = data;
    setLoading(true);
    setError(false);

    const reqBody = {
      email,
      reset_step: "first_step",
    };

    try {
      const response = await postData(apiEndpoints.resetPasswordUrl, reqBody);
      setResponseFromApi(response);
      setLoading(false);
      setStep(2);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const onSubmitStepTwo = async (data) => {
    const { otp } = data;
    const { email, state_token } = responseFromApi;
    setLoading(true);
    setError(false);

    const reqBody = {
      otp,
      email,
      state_token,
      reset_step: "second_step",
    };

    try {
      await postData(apiEndpoints.resetPasswordUrl, reqBody);
      setLoading(false);
      setResponseFromApi((responseFromApi) => ({
        ...responseFromApi,
        otp,
      }));
      setStep(3);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const onSubmitStepThree = async (data) => {
    const { newPassword, newPasswordAgain } = data;
    setLoading(true);
    setError(false);
    const { email, state_token, otp } = responseFromApi;

    const reqBody = {
      password1: newPassword,
      password2: newPasswordAgain,
      email,
      reset_step: "third_step",
      state_token,
      otp,
    };

    try {
      const response = await postData(apiEndpoints.resetPasswordUrl, reqBody);
      setLoading(false);
      setSuccess(
        "You have successfully reset your password. Please log in with your new password."
      );
      setTimeout(() => {
        props.history.push(ROUTES.login);
      }, 3000);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  return (
    <div role="main" className="container space-2">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-5">
          <p className="h3 text-primary text-center mb-3">
            Reset your password
          </p>
          {error && <p className="alert alert-danger mb-4 small">{error}</p>}
          {success && (
            <p className="alert alert-success mb-4 small">{success}</p>
          )}
          <p className="mb-3 text-center font-weight-bold">Step {step} of 3</p>

          {(() => {
            switch (step) {
              case 1:
                return (
                  <ResetPasswordStepOne
                    onSubmitStepOne={onSubmitStepOne}
                    loading={loading}
                  />
                );
              case 2:
                return (
                  <ResetPasswordStepTwo
                    onSubmitStepTwo={onSubmitStepTwo}
                    loading={loading}
                  />
                );
              case 3:
                return (
                  <ResetPasswordStepThree
                    onSubmitStepThree={onSubmitStepThree}
                    loading={loading}
                  />
                );
              default:
                return null;
            }
          })()}

          <p className="text-center mt-3">
            Go back to <Link to={ROUTES.login}>login.</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
