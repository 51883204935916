import React, { Fragment } from "react";
import ButtonLoader from "../ButtonLoader/ButtonLoader.component";

export default function CustomWidgetButton({
  disabled,
  customStyles,
  loading,
  value,
  onClick,
  type,
  name,
  buttonType,
  width,
}) {
  return (
    <Fragment>
      <button
        type={type}
        className={`btn btn-sm font-weight-bold ${
          buttonType ? buttonType : "btn-primary"
        } ${width}`}
        onClick={onClick}
        disabled={disabled}
        name={name}
        style={{
          backgroundColor: customStyles?.buttonBackgroundColor,
          borderColor: customStyles?.buttonBackgroundColor,
          color: customStyles?.buttonTextColor,
        }}
      >
        {loading ? <ButtonLoader /> : `${value}`}
      </button>
    </Fragment>
  );
}
