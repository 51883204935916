import React from "react";
import loader from "../../assets/loadinggif.gif";
import embed from "../../assets/embed.svg";
export default function ButtonLoader({ type, noMarginLeft }) {
  return (
    <div className="d-flex flex-column justify-content-center">
      <img
        src={embed}
        alt=""
        style={{
          alignSelf: "center",
          height: "20%",
          width: "20%",
        }}
      />
      <img
        src={loader}
        alt=""
        className="img-fluid"
        style={{
          alignSelf: "center",
          height: "80%",
          width: "80%",
          objectFit: "contain",
        }}
      />
    </div>
  );
}
