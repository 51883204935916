import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/all";
import LoanRequestStepOne from "./LoanRequestStepOne";
import LoanRequestStepTwo from "./LoanRequestStepTwo";
import LoanRequestStepThree from "./LoanRequestStepThree";
import LoanRequestStepFour from "./LoanRequestStepFour";
import { SuccessComponent } from "../../../SuccessComponent";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import Loader from "../../../../../Loader/Loader";
import { getDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import { UserProp, WalletProp } from "../../../Dashboard/Dashboard.component";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import CONFIG from "../../../../../../helpers/config";
import moment from "moment";
import { currency } from "../../../../../utility";

export type CreateLoanData = {
  bvn_number: string | undefined;
  product_id: number;
  loan_amount: number;
  loan_tenure: number;
  aggregator_id: number | undefined;
  repayment_start_date: string;
  bvn_checker: any;
  annual_interest_rate: number;
  aggregator_loan_ref: string;
  loan_name?: string;
  business_registration_number?: string;
  phone_number?: string; // borrower_phone
  country_code?: string; // borrower_phone
};

export type StepProp = {
  handleNext: () => void;
  handleGoBack: () => void;
  walletDetails?: WalletProp | null;
  loanProducts?: LoanProduct[] | [];
  setLoanData?: Dispatch<SetStateAction<LoanDataProp>>;
  setProcessedLoanData?: Dispatch<SetStateAction<ProcessedLoan | undefined>>;
  loanData?: any;
  processedLoanData?: ProcessedLoan;
  encryptedKey?: string;
  currency?: string;
  createLoanData?: CreateLoanData;
  setCreateLoanData?: (a: CreateLoanData) => void;
};

export type LoanProduct = {
  id: number;
  product_name: string;
  maximum_tenor: number;
  maximum_amount: number;
  interest_rate: number;
  aggregator_id: number;
};

export type ProcessedLoan = {
  currency: { code: "" };
  totalPrincipalDisbursed: number;
  aggregator_loan_ref: string;
  batch_loan_number: null;
  totalInterestCharged: number;
  loanTermInDays: number;
  totalRepaymentExpected: number;
  loan_application_referenceno: string;
  repayment_cycle: string;
  message: string;
  product_name: string;
};

export type LoanDataProp = {
  productId: number;
  loanTenor: number;
  principalAmount: number;
  interest: number;
  loanEffectiveDate: string;
  aggregator_id: number;
};

const LoanRequest = ({ wallet, currency, useWallet }: UserProp) => {
  let location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loanProducts, setLoanProducts] = useState<LoanProduct[] | []>([]);
  const [walletDetails, setWalletDetails] = useState<WalletProp | null>();
  const [loanData, setLoanData] = useState<LoanDataProp>({
    productId: 0,
    principalAmount: 0,
    loanTenor: 0,
    loanEffectiveDate: "",
    interest: 0,
    aggregator_id: 0,
  });
  const [processedLoanData, setProcessedLoanData] = useState<ProcessedLoan>();
  const [createLoanData, setCreateLoanData] = useState<CreateLoanData>({
    loan_amount: 0,
    loan_name: "",
    product_id: 0,
    loan_tenure: 0,
    aggregator_id: wallet.aggregator_id,
    repayment_start_date: moment().format("DD/MM/YYYY"),
    bvn_checker: CONFIG.BVN_CHECKER,
    annual_interest_rate: 0,
    aggregator_loan_ref: Date.now().toString(),
    bvn_number: wallet.bvn_number,
    business_registration_number: wallet.business_registration_number,
    phone_number: wallet.borrower_phone,
    country_code: wallet.country_code,
  });

  useEffect(() => {
    appInsights.trackPageView({ name: "LoanRequest.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    if (wallet && wallet.aggregator_id) {
      setLoanData({ ...loanData, aggregator_id: wallet.aggregator_id });
    }
  }, [wallet]);

  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${wallet.customer_id}&aggregator_id=${wallet.aggregator_id}`,
        wallet.encryptedKey
      );
      setWalletDetails(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRequest },
      });
    }
    setIsLoading(false);
  }

  const getAllLoanProducts = async () => {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getLoanProducts}?aggregator_id=${wallet.aggregator_id}&country_code=${wallet.country_code}&aggregator_domain=${window.location.origin}`,
        wallet.encryptedKey
      );
      setLoanProducts(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRequest },
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllLoanProducts();
    if (wallet.customer_id && wallet.aggregator_id) {
      fetchWallet();
    }
  }, []);

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setShowSuccess(true);
      }, 2000);
    }
  };

  const handleGoBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      history.push(ROUTES.widgetLoan);
    }
  };

  const steps =
    currency === "NGN" && useWallet && walletDetails?.accountNumber
      ? [
          <LoanRequestStepOne
            walletDetails={walletDetails}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            currency={currency}
          />,
          <LoanRequestStepTwo
            loanProducts={loanProducts}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            loanData={loanData}
            setLoanData={setLoanData}
            encryptedKey={wallet.encryptedKey}
            setProcessedLoanData={setProcessedLoanData}
            setCreateLoanData={setCreateLoanData}
            createLoanData={createLoanData}
          />,
          <LoanRequestStepThree
            processedLoanData={processedLoanData}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
          />,
          <LoanRequestStepFour
            encryptedKey={wallet.encryptedKey}
            createLoanData={createLoanData}
            handleGoBack={() => setCurrentStep(0)}
            handleNext={handleNext}
            walletDetails={walletDetails}
            loanData={loanData}
          />,
        ]
      : [
          <LoanRequestStepTwo
            loanProducts={loanProducts}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            loanData={loanData}
            setLoanData={setLoanData}
            encryptedKey={wallet.encryptedKey}
            setProcessedLoanData={setProcessedLoanData}
            setCreateLoanData={setCreateLoanData}
            createLoanData={createLoanData}
          />,
          <LoanRequestStepThree
            processedLoanData={processedLoanData}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
          />,
          <LoanRequestStepFour
            encryptedKey={wallet.encryptedKey}
            createLoanData={createLoanData}
            handleGoBack={() => setCurrentStep(0)}
            handleNext={handleNext}
            walletDetails={walletDetails}
            loanData={loanData}
          />,
        ];

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Request Successful!"
        body="Your request has been received and will be reviewed for approval"
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <div onClick={() => history.push(ROUTES.widgetLoan)} className="cursor">
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>{currentStep < 2 ? "Loan request" : null}</h4>
      {steps[currentStep]}
    </div>
  );
};

export default LoanRequest;
