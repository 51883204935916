import React, { useEffect, useState } from "react";
import styles from "./Topup.module.scss";
import TopUpStepOne from "./components/TopUpStepOne";
import TopUpStepTwo from "./components/TopUpStepTwo";
import logo from "../../../../assets/advancly-logo-full.png";
import loader from "../../../../assets/loadinggif.gif";
import success from "../../../../assets/success.svg";
import failure from "../../../../assets/fail.svg";
import { ROUTES } from "../../../../helpers/ROUTES";
import { useHistory } from "react-router-dom";
import { UserProp, WalletProp } from "../Dashboard/Dashboard.component";
import { getDataWithKey } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { message } from "antd";
import { errorHandler, errorToast } from "../../../../helpers/errorHandler";
import { appInsights } from "../../../AppInsight/AppInsight";

const TopupComponent = ({ wallet }: UserProp) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const [walletDetails, setWalletDetails] = useState<any>();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Topup.component.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${wallet.customer_id}&aggregator_id=${wallet.aggregator_id}`,
        wallet.encryptedKey
      );
      setWalletDetails(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
        appInsights.trackException({
            exception: error,
            properties: {fileName : TopupComponent}
        })
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (wallet.customer_id && wallet.aggregator_id) {
      fetchWallet();
    }
  }, [wallet]);

  const handleGoBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      history.push(ROUTES.widgetDashboard);
    }
  };

  const handleNext = () => {
    history.push(ROUTES.widgetDashboard);
  };

  const handleValidateTransfer = () => {
    setShowFailure(false);
    setShowSuccess(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowSuccess(true);
    }, 200);
  };

  const steps = [
    <TopUpStepTwo
      walletDetails={walletDetails}
      handleGoBack={handleGoBack}
      handleNext={handleNext}
    />,
  ];

  const successModal = () => {
    return (
      <>
        <div className="d-flex justify-content-center">
          <img
            src={success}
            alt=""
            className="img-fluid"
            style={{
              height: "138px",
              width: "137px",
              objectFit: "contain",
            }}
          />
        </div>

        <p className="title text-center">Transaction Complete</p>
        <p className="text-center">
          You have successfully topped up your account
        </p>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary btn-radius"
            onClick={() => history.push(ROUTES.widgetDashboard)}
          >
            Done
          </button>
        </div>
      </>
    );
  };

  const failureModal = () => {
    return (
      <>
        <div className="d-flex justify-content-center">
          <img
            src={failure}
            alt=""
            className="img-fluid"
            style={{
              height: "138px",
              width: "137px",
              objectFit: "contain",
            }}
          />
        </div>

        <p className="title text-center">Transaction Failed</p>
        <p className="text-center">Something went wrong</p>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary btn-radius"
            onClick={handleValidateTransfer}
          >
            Try again
          </button>
        </div>
      </>
    );
  };

  return (
    <div className={styles.topup}>
      {isLoading ? (
        <>
          <br />
          <div className="d-flex justify-content-center">
            <img
              src={logo}
              alt=""
              className="img-fluid"
              style={{
                height: "24px",
                width: "126.53px",
                objectFit: "contain",
              }}
            />
          </div>
          <br />
          <br />
          <div className="d-flex justify-content-center">
            <img
              src={loader}
              alt=""
              className="img-fluid"
              style={{
                height: "20%",
                width: "50%",
                objectFit: "contain",
              }}
            />
          </div>
          <br />
          <br />
          {/*<p className="text-center">Checking transaction status...</p>*/}
        </>
      ) : showSuccess ? (
        successModal()
      ) : showFailure ? (
        failureModal()
      ) : (
        steps[currentStep]
      )}
    </div>
  );
};

export default TopupComponent;
