export const ROUTES = {
  resetPassword: "/reset_password",
  login: "/login",
  changePassword: "/change-password",

  // Widget Routes
  widget: "/create-loan-account",
  widgetEmbedApiSecurity: "/widget",
  widgetUpdateCard: "/create-loan-account/update-card-details",
  widgetPayments: "/create-loan-account/payments",
  widgetMakePayment: "/create-loan-account/make-payment",
  widgetViewRepayments: "/create-loan-account/repayments",
  widgetLoan: "/create-loan-account/loan",
  widgetLoanRequest: "/create-loan-account/loan/request",
  widgetLoanHistory: "/create-loan-account/loan/history",
  widgetAllLoans: "/create-loan-account/loan/all-loans",
  widgetALoanDetails: "/create-loan-account/loan/all-loans/details",
  widgetLoanOffer: "/create-loan-account/offer",
  widgetLoanRepaymentAmount: "/create-loan-account/loan/repayment/amount",
  widgetLoanRepaymentMethods: "/create-loan-account/loan/repayment/methods",
  widgetLoanRepaymentViaWallet: "/create-loan-account/loan/repayment/wallet",
  widgetLoanRepaymentViaExistingCard:
    "/create-loan-account/loan/repayment/existing-card",
  widgetLoanRepaymentViaNewCard: "/create-loan-account/loan/repayment/new-card",
  widgetLoanRepaymentProof: "/create-loan-account/loan/repayment/proof",
  widgetDashboard: "/create-loan-account/dashboard",
  widgetTopUp: "/create-loan-account/topup",
  widgetProfile: "/create-loan-account/profile",
  widgetProfileInfo: "/create-loan-account/profile/info",
  widgetCard: "/create-loan-account/profile/card",
  widgetPin: "/create-loan-account/profile/pin",
  widgetTransactions: "/create-loan-account/profile/transactions",
  widgetWithdrawal: "/create-loan-account/profile/withdrawal",
  widgetSetPin: "/create-loan-account/profile/setPin",
  widgetContactUs: "/create-loan-account/contact-us",
  widgetLoanRepaymentOptionView: "/create-loan-account/loan/repayment/options",
  widgetLoanRepaymentMpesa: "/create-loan-account/loan/repayment/options/mpesa",
};
