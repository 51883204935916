const masterBaseUrl = "https://advancly-api-master.test.vggdev.com/api/v1";
const master2BaseUrl = "https://advancly-api-master2.test.vggdev.com/api/v1";
const stagingBaseUrl = "https://advancly-api-master.staging.vggdev.com/api/v1";
const sprint2BaseUrl = "https://advancly-api-sprint2.test.vggdev.com/api/v1";
const sprint4BaseUrl = "https://advancly-api-sprint4.test.vggdev.com/api/v1";

const baseUrl = sprint4BaseUrl;

export const getAppInsightKey = () => {
  // @ts-ignore
  switch (window.env.baseUrl) {
    //Sprint 3
    case "https://advancly-api-sprint3.test.vggdev.com/api/v1":
      return "4ef6272a-3923-4888-9316-8c910b01919e";
    // Master
    case "https://advancly-api-master.test.vggdev.com/api/v1":
      return "8caccf7d-b860-4ac2-bd04-2be81e9a63ed";
    // Staging
    case "https://advancly-api-master.staging.vggdev.com/api/v1":
      return "1ea2d609-3c11-47a7-abaf-aa6428caf8d2";
    //Prod
    case "https://api2.advancly.com/api/v1":
      return "d3f7f939-0c41-495a-ad1c-1ca8adf85a6d";
    // Using App Insight Master 2 for local development bcos 'window.env.baseUrl' returns undefined on local
    default:
      return "8caccf7d-b860-4ac2-bd04-2be81e9a63ed";
  }
};

const CONFIG = {
  BASE_URL:
    // process.env.NODE_ENV === "development" ? baseUrl : window.env.baseUrl2,
    baseUrl,
  BASE_URL2:
    // process.env.NODE_ENV === "development" ? baseUrl : window.env.baseUrl2,
    baseUrl,
  PAYSTACK_PUBLIC_KEY:
    process.env.NODE_ENV === "development"
      ? "pk_test_6e6904d11efb3b40c7cad5611bcba13fb362f4ec"
      : window.env.paystackPublicKey,
  OKRA_PUBLIC_KEY:
    process.env.NODE_ENV === "development"
      ? "a07643b9-8b61-5f42-9da8-1d14d17d73e9"
      : window.env.okraPublicKey,
  OKRA_CLIENT_TOKEN:
    process.env.NODE_ENV === "development"
      ? "5f11699c4eb4ca3ee1cb1256"
      : window.env.okraToken,
  OKRA_ENV:
    process.env.NODE_ENV === "development"
      ? "production-sandbox"
      : window.env.okraEnv,
  OKRA_SHORT_URL:
    process.env.NODE_ENV === "development"
      ? "veGGSjGB8"
      : window.env.okraShortUrl,
  BVN_CHECKER:
    process.env.NODE_ENV === "development" ? false : window.env.bvnChecker,
  APPINSIGHTS_KEY: getAppInsightKey(),
  USE_MOCK: false,
  MONO_BASE_URL:
    //  process.env.NODE_ENV === "development"
    baseUrl === sprint4BaseUrl || sprint2BaseUrl
      ? "https://api.withmono.com"
      : window.env.monoBaseUrl,
  MONO_PUBLIC_KEY:
    // process.env.NODE_ENV === "development"
    baseUrl === sprint4BaseUrl || sprint2BaseUrl
      ? "test_pk_gna2j1wht0f95k6mky3t"
      : window.env.monoPublicKey,
  MONO_SECRET_KEY:
    // process.env.NODE_ENV === "development"
    baseUrl === sprint4BaseUrl || sprint2BaseUrl
      ? "test_sk_ibjvv4dqyq9x7nv89kjd"
      : window.env.monoSecretKey,
};

export default CONFIG;
