import { message } from "antd";
import { deviceType } from "react-device-detect";

export const errorHandler = (error) => {
  if (error && error.message.toLowerCase() === "network error") {
    return "Unable to connect to Advancly services.";
  }
  if (error.response) {
    return error.response.data.message;
  } else if (error.request) {
    return error.request;
  } else {
    return "Unable to connect to Advancly services.";
  }
};

export const errorToast = (error) => {
  message.error({
    content: error,
    className: deviceType === "mobile" ? "toastErrorMobile" : "toastError",
  });
};
