import axios from "axios";
import CONFIG from "../helpers/config";
import { appInsights } from "../components/AppInsight/AppInsight";

export const token = () => {
  // const token = localStorage.getItem("token");
  const token = localStorage.getItem("sso_auth_token");
  if (!token) {
    return null;
  }
  return token;
};

const baseURL = CONFIG.BASE_URL2;
const baseURL2 = CONFIG.BASE_URL2;
// const baseURL = "http://c27b6edca1d7.ngrok.io/api/v1";

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token()}`,
    };
    // you can also do other modification in config
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const axiosInstance2 = axios.create({
  baseURL: baseURL2,
});

axiosInstance2.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token()}`,
    };
    // you can also do other modification in config
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance2.interceptors.response.use(undefined, async function (error) {
  appInsights.trackException({ exception: error });
  return Promise.reject(error);
});

export const getData = async (url) => {
  const data = await axiosInstance({
    method: "GET",
    url,
  });
  return data.data;
};

export const getDataWithKey = async (url, encryptedKey) => {
  const data = await axiosInstance({
    method: "GET",
    url,
    headers: {
      HTTP_KEY: encryptedKey,
    },
  });
  return data.data;
};

export const getDotNetDataWithKey = async (url, encryptedKey) => {
  const data = await axiosInstance2({
    method: "GET",
    url,
    headers: {
      HTTP_KEY: encryptedKey,
    },
  });
  return data.data;
};

export const postData = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
  });
  return data.data;
};

export const postDataWithKey = async (url, reqBody, encryptedKey) => {
  const data = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
    headers: {
      HTTP_KEY: encryptedKey,
    },
  });
  return data.data;
};

export const postDotNetDataWithKey = async (url, reqBody, encryptedKey) => {
  const data = await axiosInstance2({
    method: "POST",
    url,
    data: reqBody,
    headers: {
      HTTP_KEY: encryptedKey,
    },
  });
  return data.data;
};

export const putDotNetDataWithKey = async (url, reqBody, encryptedKey) => {
  const data = await axiosInstance2({
    method: "PUT",
    url,
    data: reqBody,
    headers: {
      HTTP_KEY: encryptedKey,
    },
  });
  return data.data;
};

export const patchData = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "PATCH",
    url,
    data: reqBody,
  });
  return data.data;
};

export const deleteData = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "DELETE",
    url,
    data: reqBody,
  });
  return data.data;
};
