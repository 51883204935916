import * as yup from "yup";

// Regular expression for phone number
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const numberRegExp = /[0-9]/;
export const yupValidators = {
  email: yup
    .string()
    .trim()
    .email("This is an invalid email")
    .required("Please enter your email")
    .min(5, "Email must have at least 5 characters")
    .max(256, "Email cannot be longer than 256 characters"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Password must have at least 8 characters")
    .max(50, "Password cannot be longer than 50 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(/[0-9]/, "Password must contain a number"),
  phoneNumber: yup
    .string()
    .required("Please enter your phone number")
    .matches(phoneRegExp, "Invalid phone number")
    .min(7, "Phone number must have at least 7 digits")
    .max(20, "Phone number cannot have more than 20 digits"),
  amount: yup
    .string()
    .required("Please enter an amount")
    .matches(numberRegExp, "Invalid amount")
    .min(1, "Amount must have at least 1 digit")
    .max(10, "Amount cannot have more than 10 digits"),
  otp: yup
    .string()
    .required("Please enter OTP")
    .matches(numberRegExp, "Invalid OTP"),
  newPassword: yup
    .string()
    .required("Please enter your new password")
    .min(8, "Password must have at least 8 characters")
    .max(128, "Password cannot be longer than 128 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(/[0-9]/, "Password must contain a number"),
};
