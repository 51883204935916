import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  BiRightArrowAlt,
  IoMdArrowRoundBack,
  MdArrowForwardIos,
} from "react-icons/all";
import { ROUTES } from "../../../../helpers/ROUTES";
import user from "../../../../assets/loans/user.svg";
import bag from "../../../../assets/loans/bag.svg";
import styles from "../../WidgetFlow.module.scss";
import { deviceType } from "react-device-detect";
import { appInsights } from "../../../AppInsight/AppInsight";

const LoansComponent = () => {
  let location = useLocation();
  const history = useHistory();
  useEffect(() => {
    appInsights.trackPageView({
      name: "Loans.component.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <div
        onClick={() => history.push(ROUTES.widgetDashboard)}
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Loans</h4>
      <br />
      <div
        className="card cursor bg-primary"
        style={{ height: "108px" }}
        onClick={() => history.push(ROUTES.widgetLoanRequest)}
      >
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              className={`${
                deviceType === "mobile" ? styles.iconMobile : styles.icon
              } icon__primary mr-3`}
              style={{ borderRadius: "10px" }}
            >
              <img
                src={user}
                alt=""
                className="img-fluid"
                style={{ width: "11.48px", height: "13.2px" }}
              />
            </div>
            <div>
              <p className="font-weight-bold text-white padding-none">
                Request for new loan
              </p>
              <small className="text-white padding-none">
                Get a new loan offer
              </small>
            </div>
          </div>

          <BiRightArrowAlt className="text-white" size="20px" />
        </div>
      </div>
      <br />

      <div
        className="card cursor"
        style={{ height: "108px" }}
        onClick={() => history.push(ROUTES.widgetLoanHistory)}
      >
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              className={`${
                deviceType === "mobile" ? styles.iconMobile : styles.icon
              } icon__primary mr-3`}
              style={{ backgroundColor: "#9DA9B3", borderRadius: "10px" }}
            >
              <img
                src={bag}
                alt=""
                className="img-fluid"
                style={{ width: "11.48px", height: "13.2px" }}
              />
            </div>
            <div>
              <p className="font-weight-bold text-primary padding-none">
                Loan History
              </p>
              <small className=" padding-none">See your loan offers</small>
            </div>
          </div>

          <BiRightArrowAlt className="" size="20px" />
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default LoansComponent;
