export const widgetSettings = {
  data: [
    {
      setting_id: 1,
      aggregator_id: 10,
      country_code: "KE",
      setting_name: "use_wallet",
      setting_value: "1",
    },
  ],
  message: null,
  status: true,
  status_code: 200,
};
