import React, { useEffect } from "react";
import "./style/theme.css";
import "./App.less";
// import "antd/dist/antd.css";
import "./style/styles.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import CreateAccount from "./components/CreateAccount";
import WidgetFlow from "./components/Widget/WidgetFlow";
// import Footer from "./components/Footer";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import ResetPassword from "./components/ResetPassword";
import { ROUTES } from "./helpers/ROUTES";
import WidgetFlowEmbedApi from "./components/Widget/EmbedApiSecurity/WidgetFlowEmbedApi";
import DeploymentStatus from "./components/Widget/WidgetRevampv2/DeploymentStatus";
import CONFIG from "./helpers/config";

function App() {
  useEffect(() => {
    document.body.style.setProperty(
      "--primary-color",
      JSON.parse(localStorage.getItem("customStyles"))?.primaryColor
    );
    document.body.style.setProperty(
      "--primary-light-color",
      JSON.parse(localStorage.getItem("customStyles"))?.primaryLightColor
    );
    document.body.style.setProperty(
      "--primary-deep-color",
      JSON.parse(localStorage.getItem("customStyles"))?.primaryDeepColor
    );
  }, [localStorage.getItem("customStyles")]);

  useEffect(() => {
    console.log({ CONFIG });
  }, []);
  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.login} component={Login} />
        <Route exact path={ROUTES.resetPassword} component={ResetPassword} />
        <Route path="/create-account" component={CreateAccount} />
        <Route path="/create-account/:id" component={CreateAccount} />
        <Route path={ROUTES.widget} component={WidgetFlow} />
        <Route
          path={ROUTES.widgetEmbedApiSecurity}
          component={WidgetFlowEmbedApi}
        />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms-of-use" component={Terms} />
        <Route exact path="/deployment" component={DeploymentStatus} />
        <Route exact path="/" component={Login} />
      </Switch>
    </Router>
  );
}

export default App;
