import React from "react";

export default function Loader({ light = false }) {
  return (
    <span
      className={`spinner-border spinner-border-sm ml-2 ${
        light ? "text-white" : "text-primary"
      }`}
    ></span>
  );
}
