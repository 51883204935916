import React, { Fragment, useEffect, useState } from "react";
import CustomWidgetButton from "./../../CustomWidgetElements/CustomWidgetButton.component";
import WidgetInnerPageLoader from "../WidgetInnerPageLoader/WidgetInnerPageLoader.component";
import { CustomPinInputField } from "../../CustomHTMLElements/CustomPinInputField.component";
import { Form, Input, Select } from "antd";

export default function CorporateSignUp({
  edit_widget,
  business_registration_number,
  businessRegNumberValidated,
  customStyles,
  otp,
  email,
  identity_number,
  bvnLoading,
  handleBvnValidation,
  otpLoading,
  onOtpInput,
  handleChange,
  loading,
  handleCacValidation,
  handleOtpValidation,
  borrower_phone,
  country_code,
  worldCountries,
  goBack,
  showBackBtn,
  company_name,
}) {
  const [counter, setCounter] = useState(120);

  const padTime = (time) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };
  const format = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${padTime(seconds)}`;
  };

  useEffect(() => {
    if (businessRegNumberValidated) {
      let timer;
      if (counter > 0) {
        timer = setTimeout(() => setCounter((c) => c - 1), 1000);
      }

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [counter, businessRegNumberValidated]);

  // Show Loader when OTP or BVN is loading
  if (bvnLoading || otpLoading || loading) {
    return <WidgetInnerPageLoader />;
  }

  return (
    <div>
      {!businessRegNumberValidated ? (
        <Fragment>
          <p className="title">KYC Information</p>
          <p className="text-muted">
            Enter the following information to proceed
          </p>
          <Form layout="vertical">
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              initialValue={borrower_phone}
              name="borrower_phone"
              label="Phone Number"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Input
                onChange={(e) => handleChange("borrower_phone", e.target.value)}
                disabled={!edit_widget}
              />
            </Form.Item>
            <br />
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              initialValue={email}
              name="email"
              label="Email"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Input
                onChange={(e) => handleChange("email", e.target.value)}
                disabled={!edit_widget}
              />
            </Form.Item>
            <br />
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              initialValue={business_registration_number}
              name="business_registration_number"
              label="Business Registration Number"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Input
                onChange={(e) =>
                  handleChange("business_registration_number", e.target.value)
                }
                disabled={!edit_widget}
              />
            </Form.Item>
            <br />
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              initialValue={company_name}
              name="company_name"
              label="Company Name"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Input
                onChange={(e) => handleChange("company_name", e.target.value)}
                disabled={!edit_widget}
              />
            </Form.Item>
            <br />
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              initialValue={identity_number}
              name="identity_number"
              label="Identity Number"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Input
                onChange={(e) =>
                  handleChange("identity_number", e.target.value)
                }
                disabled={!edit_widget}
              />
            </Form.Item>
            <br />
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              name="country_code"
              label="Country"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Select
                placeholder="Select your country"
                allowClear
                defaultValue={
                  worldCountries.find((c) => c.iso2 === country_code).name
                }
                disabled
              >
                {worldCountries &&
                  worldCountries.length > 0 &&
                  worldCountries.map((item) => (
                    <Select.Option>{item.name}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <br />
            <div>
              <span className="bg-light-grey " style={{ fontSize: "12px" }}>
                By proceeding to the next step, I acknowledge that I have <br />{" "}
                read and agree to the terms and conditions outlined in <br />
                Advancly's
                <a
                  href="https://advancly.com/terms-conditions/"
                  rel="noreferrer"
                  className="pl-1 pr-1 color-mid-blue"
                  target="_blank"
                >
                  Terms of Use
                </a>
                and
                <a
                  href="https://advancly.com/privacy-policy/"
                  rel="noreferrer"
                  className="pl-1 color-mid-blue"
                  target="_blank"
                >
                  Privacy Policy.
                </a>
              </span>
            </div>{" "}
            <br />
            {!businessRegNumberValidated && (
              <div className="d-flex justify-content-end">
                <div className="d-flex align-items-center">
                  {showBackBtn ? (
                    <p
                      className="text-primary padding-none mr-3 cursor-pointer"
                      onClick={() => goBack()}
                    >
                      Go Back
                    </p>
                  ) : null}
                  <button
                    className="btn btn-primary btn-radius"
                    onClick={handleCacValidation}
                    disabled={
                      bvnLoading ||
                      !business_registration_number ||
                      !identity_number
                    }
                  >
                    {bvnLoading ? (
                      <span className="spinner-border text-white" />
                    ) : (
                      "Next"
                    )}
                  </button>
                </div>
              </div>
            )}
          </Form>
        </Fragment>
      ) : (
        <Fragment>
          <p className="title">OTP Verification</p>
          <p className="text-muted">
            {`A one time password was sent to your email address ${email[0]}${email[1]}${email[2]}${email[3]}*************, please enter below`}
          </p>
          <div>
            <CustomPinInputField
              setValue={(value) => onOtpInput(value)}
              length={5}
              label="Enter OTP"
              focus={true}
            />

            {counter !== 0 && !otpLoading && (
              <div className="d-flex justify-content-end align-items-center text-danger mb-3">
                <i className="ri-time-line mr-3"></i>
                {format(counter)}
              </div>
            )}

            {/*<p className="mt-3">A one time password was sent to {email}</p>*/}

            <div className="d-flex align-items-center justify-content-end">
              {counter === 0 ? (
                <p
                  className="text-primary font-weight-bold padding-none mr-3"
                  onClick={() => {
                    handleBvnValidation();
                    setCounter(120);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Send OTP again
                </p>
              ) : null}
              <CustomWidgetButton
                loading={otpLoading}
                customStyles={customStyles}
                onClick={handleOtpValidation}
                type="button"
                value="Next"
                disabled={otp?.length !== 5}
              />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
