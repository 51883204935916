import success from "../../../assets/success.svg";
import fail from "../../../assets/fail.svg";
import React from "react";

export type SuccessFailureProp = {
  title: string;
  body: string;
  handler: () => void;
  btnText: "Done" | "Try Again" | "Ok";
  type: "success" | "failure";
};

export const SuccessComponent = ({
  handler,
  body,
  title,
  btnText,
  type,
}: SuccessFailureProp) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <img
          src={type ? success : fail}
          alt=""
          className="img-fluid"
          style={{
            height: "138px",
            width: "137px",
            objectFit: "contain",
          }}
        />
      </div>

      <p className="title text-center">{title}</p>
      <p className="text-center">{body}</p>
      <br />
      <br />
      <div className="d-flex justify-content-center">
        <button className="btn btn-primary btn-radius" onClick={handler}>
          {btnText}
        </button>
      </div>
    </>
  );
};
