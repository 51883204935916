import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Form, Input, message, Select } from "antd";
import { SuccessComponent } from "../../SuccessComponent";
import { UserProp } from "../../Dashboard/Dashboard.component";
import {
  getDataWithKey,
  postData,
  postDataWithKey,
} from "../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { errorHandler, errorToast } from "../../../../../helpers/errorHandler";
import Loader from "../../../../Loader/Loader";
import { ROUTES } from "../../../../../helpers/ROUTES";
import { appInsights } from "../../../../AppInsight/AppInsight";

type SecurityQuestion = {
  id: number;
  question: string;
  code: string;
};
const TransactionPin = ({ wallet, isApi, handleDashboard }: UserProp) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [showReset, setShowReset] = useState(false);
  const [showNextScreen, setShowNextScreen] = useState(false);
  const [showDone, setShowDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [securityQuestions, setSecurityQuestions] = useState<
    SecurityQuestion[]
  >([]);
  const [securityData, setSecurityData] = useState({
    borrowerId: wallet.customer_id,
    question_id: "",
    answer: "",
  });

  useEffect(() => {
    appInsights.trackPageView({ name: "TransactionPin.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    setShowDone(false);
    setShowNextScreen(false);
  }, []);

  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        apiEndpoints.getSecurityQuestions,
        wallet.encryptedKey
      );
      setSecurityQuestions(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
        appInsights.trackException({
            exception: error,
            properties: {fileName : TransactionPin}
        })
    }
    setIsLoading(false);
  }

  async function submitSecurityAnswer() {
    setIsLoading(true);
    try {
      await postDataWithKey(
        apiEndpoints.saveSecurityQuestionAndAnswer,
        securityData,
        wallet.encryptedKey
      );
      setShowNextScreen(true);
    } catch (error) {
      errorToast(errorHandler(error));
        appInsights.trackException({
            exception: error,
            properties: {fileName : TransactionPin}
        })
    }
    setIsLoading(false);
  }

  async function saveCustomerPin(data: { pin: string; confirmPin: string }) {
    setIsLoading(true);
    try {
      if (showReset) {
        await postDataWithKey(
          apiEndpoints.resetCustomerPin,
          {
            question_id: securityData.question_id,
            answer: securityData.answer,
            borrower_id: wallet.customer_id,
            aggregator_id: wallet.aggregator_id,
            pin: data.pin,
            type: 5,
          },
          wallet.encryptedKey
        );
      } else {
        await postDataWithKey(
          apiEndpoints.saveCustomerPin,
          {
            borrowerId: wallet.customer_id,
            pin: data.pin,
            type: 5,
          },
          wallet.encryptedKey
        );
      }

      setShowDone(true);
    } catch (error) {
      errorToast(errorHandler(error));
        appInsights.trackException({
            exception: error,
            properties: {fileName : TransactionPin}
        })
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (wallet.customer_id && wallet.aggregator_id) {
      fetchWallet();
    }
  }, [wallet]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  if (showNextScreen && !showDone) {
    return (
      <>
        <h4>Security</h4>
        <p>
          {!showReset
            ? " Kindly set a security question before creating your transaction pin."
            : " Kindly enter your new pin"}
        </p>
        <Form
          layout="vertical"
          form={form2}
          onFinish={(data) => saveCustomerPin(data)}
        >
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            name="pin"
            label="New PIN"
            rules={[
              { required: true, message: "Required field" },
              {
                pattern: /^\d*[.]?\d*$/,
                message: "Enter numbers from 0 - 9",
              },
              {
                max: 4,
                message: "Enter maximum of 4 digits",
              },
              {
                min: 4,
                message: "Enter a minimum of 4 digits",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            name="confirmPin"
            label="Confirm PIN"
            dependencies={["newPin"]}
            rules={[
              { required: true, message: "Required field" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("pin") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two pins that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "10%" }}
          >
            <div className="d-flex align-items-center">
              <p
                className="text-primary padding-none mr-3 cursor-pointer"
                onClick={() => setShowNextScreen(false)}
              >
                Go Back
              </p>
              <button className="btn btn-primary btn-radius">
                {showReset ? "Reset" : "Save"}
              </button>
            </div>
          </div>
        </Form>
      </>
    );
  }

  if (showDone) {
    return (
      <SuccessComponent
        title={showReset ? "Transaction PIN Changed" : "Transaction PIN Set"}
        body={
          showReset
            ? "You have successfully changed your transaction pin"
            : "You have successfully set your transaction pin"
        }
        handler={() =>
          isApi && handleDashboard ? handleDashboard() : history.goBack()
        }
        btnText="Done"
        type="success"
      />
    );
  }
  return (
    <>
      <h4>Security</h4>
      <p>
        {!showReset
          ? "Kindly set a security question before creating your transaction pin."
          : " Kindly answer your security question before resetting your transaction pin."}
      </p>
      <Form
        layout="vertical"
        form={form}
        onFinish={() => {
          if (showReset) {
            setShowNextScreen(true);
          } else {
            submitSecurityAnswer();
          }
        }}
      >
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="question_id"
          label="Security Question"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Select
            onChange={(value) =>
              setSecurityData({ ...securityData, question_id: value })
            }
            placeholder="Select"
            allowClear
          >
            {securityQuestions.map((item) => (
              <Select.Option value={item.id}>{item.question}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="answer"
          label="Security Question Answer"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Input
            onChange={(e) =>
              setSecurityData({ ...securityData, answer: e.target.value })
            }
          />
        </Form.Item>
        <div
          className={`d-flex align-items-center ${
            showReset ? "  justify-content-end" : " justify-content-between"
          }`}
          style={{ marginTop: "10%" }}
        >
          {!showReset && (
            <div>
              <button
                className="btn advancly-btn-nobg btn-radius"
                onClick={() => setShowReset(true)}
              >
                Reset Pin
              </button>
            </div>
          )}
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={() =>
                isApi && handleDashboard
                  ? handleDashboard()
                  : history.push(ROUTES.widgetProfile)
              }
            >
              Go Back
            </p>
            <button className="btn btn-primary btn-radius">Next</button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default TransactionPin;
