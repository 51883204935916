import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/all";
import { formatMoney } from "../../../../../../helpers/formatCurrency";
import { getDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import NewLoader from "../../../components/NewLoader";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import { CurrencyProp } from "../../../types";

export type RepaymentScheduleProp = {
  onCancel: () => void;
  borrower_id?: number;
  encryptedKey?: string;
  loan_ref?: string;
  aggregator_id?: number;
  currency: CurrencyProp;
};

type RepaymentSchedule = {
  dueDate: number[];
  totalOutstandingForPeriod: number;
  totalDueForPeriod: number;
  currency: CurrencyProp;
};

const LoanRepaymentSchedule = ({
  onCancel,
  encryptedKey,
  loan_ref,
  aggregator_id,
  currency,
}: RepaymentScheduleProp) => {
  let location = useLocation();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [isLoading, setIsLoading] = useState(false);
  const [repaymentSchedule, setRepaymentSchedule] = useState<
    RepaymentSchedule[]
  >([]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRepaymentSchedule.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function fetchRepaymentSchedule() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getLoanRepaymentSchedule}?loan_ref=${loan_ref}&aggregator_id=${aggregator_id}`,
        encryptedKey
      );
      setRepaymentSchedule(response.data?.periods);
    } catch (error : any) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepaymentSchedule },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchRepaymentSchedule();
  }, []);

  if (isLoading) {
    return <NewLoader />;
  }
  return (
    <div>
      <div className="d-flex">
        <div className="cursor mr-3" onClick={onCancel}>
          <IoMdArrowRoundBack className="cursor__back" />
        </div>
        <h4>Repayment Schedule</h4>
      </div>
      <br />
      {repaymentSchedule.length === 0 && (
        <h4 className="text-center">No Data Available</h4>
      )}

      {repaymentSchedule.length !== 0 && (
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th>Date</th>
              <th>Due</th>
              <th>Outstanding</th>
            </tr>
          </thead>
          <tbody>
            {repaymentSchedule?.map((item, index) => (
              <tr key={index}>
                <th scope="row">
                  {`${item.dueDate[2]}/${item.dueDate[1]}/${item.dueDate[0]}`}
                </th>
                <td>{formatMoney(item.totalDueForPeriod || 0, currency)}</td>
                <td>
                  {formatMoney(item.totalOutstandingForPeriod || 0, currency)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LoanRepaymentSchedule;
