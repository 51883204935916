import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/all";
import { Form, Input } from "antd";
import NewLoader from "../../../components/NewLoader";
import { SuccessComponent } from "../../../SuccessComponent";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { appInsights } from "../../../../../AppInsight/AppInsight";

const LoanRepaymentViaExistingCard = () => {
  let location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [showDone, setShowDone] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRepaymentViaExistingcard.tsx",
      isLoggedIn: true,
    });
  }, []);

  function handleConfirmRepayment() {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setDone(true);
    }, 2000);
  }

  if (isLoading) {
    return <NewLoader />;
  }
  if (done) {
    return (
      <SuccessComponent
        btnText="Done"
        body="Your loan repayment is being processed"
        title="Repayment Successful!"
        handler={() => history.push(ROUTES.widgetLoan)}
        type="success"
      />
    );
  }

  return (
    <div>
      <div onClick={() => history.goBack()} className="cursor">
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Card details</h4>

      <Form layout="vertical" form={form} onFinish={() => setShowDone(true)}>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={"**************6789"}
          name="cardNumber"
          label="Card number"
        >
          <Input readOnly />
        </Form.Item>
      </Form>

      <div className="d-flex justify-content-end" style={{ marginTop: "10%" }}>
        <div className="d-flex align-items-center">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={() => history.goBack()}
          >
            Go Back
          </p>
          <button
            className="btn btn-primary btn-radius"
            onClick={handleConfirmRepayment}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoanRepaymentViaExistingCard;
