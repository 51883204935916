import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import {
  getDataWithKey,
  postDataWithKey,
} from "../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { errorHandler, errorToast } from "../../../../../helpers/errorHandler";
import { UserProp } from "../../Dashboard/Dashboard.component";
import MastercardIcon from "../../../../../assets/mastercard.svg";
import VisaIcon from "../../../../../assets/visa.svg";
import VerveIcon from "../../../../../assets/verve.svg";
import Loader from "../../../../Loader/Loader";
import { BsCheck2All } from "react-icons/all";
import AddCard from "../../components/AddCard";
import { ROUTES } from "../../../../../helpers/ROUTES";
import { appInsights } from "../../../../AppInsight/AppInsight";

type CardProp = {
  card_id: number;
  customer_id: number;
  last_four_digits: number;
  card_type: string;
  expiry_month: number;
  expiry_year: number;
  is_default: boolean;
};

const CardInformation = ({ wallet, email, updatePayStackStatus }: UserProp) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [cards, setCards] = useState<CardProp[]>([]);
  const [walletDetails, setWalletDetails] = useState();

  useEffect(() => {
    appInsights.trackPageView({
      name: "CardInformation.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${wallet.customer_id}&aggregator_id=${wallet.aggregator_id}`,
        wallet.encryptedKey
      );
      setWalletDetails(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: {fileName : CardInformation}
      })
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (wallet.customer_id && wallet.aggregator_id) {
      fetchWallet();
    }
  }, [wallet]);

  const cardImage = (type: string) => {
    const cardType = type.toLowerCase().trim();
    return cardType?.includes("mastercard") ? (
      <img
        src={MastercardIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : cardType?.includes("visa") ? (
      <img
        src={VisaIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : cardType?.includes("verve") ? (
      <img
        src={VerveIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : null;
  };

  const fetchAddedCards = async () => {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.fetchCards}?borrower_id=${wallet.customer_id}&aggregator_id=${wallet.aggregator_id}`,
        wallet.encryptedKey
      );
      setCards(response.data);
    } catch (error) {
      message.error(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: {fileName : CardInformation}
      })
    }
    setIsLoading(false);
  };

  async function makeCardDefault(item: CardProp) {
    setIsLoading(true);
    try {
      await postDataWithKey(
        apiEndpoints.setDefaultCard,
        {
          aggregator_id: wallet.aggregator_id,
          borrower_id: wallet.customer_id,
          card_id: item.card_id,
        },
        wallet.encryptedKey
      );
      await fetchAddedCards();
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: {fileName : CardInformation}
      })
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchAddedCards();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <h4>Cards</h4>

      {cards.length === 0 ? (
        <>
          <h4 className="text-center">No Data Available</h4>
        </>
      ) : (
        cards?.map((item) => (
          <div key={item.card_id}>
            <div className="card ">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className=" mr-3">{cardImage(item.card_type)}</div>
                  <div>
                    <p className="font-weight-bold text-dark padding-none">
                      {`**** **** **** ${item.last_four_digits}`}
                      <small className="ml-2 text-success">
                        {item.is_default && (
                          <span
                            className="text-success"
                            style={{ marginTop: "-4rem" }}
                          >
                            <BsCheck2All size="1.3rem" />
                          </span>
                        )}
                      </small>
                    </p>
                    <small className="text-muted padding-none">
                      {`${item.expiry_month}/${item.expiry_year}`}
                    </small>
                  </div>
                </div>

                {!item.is_default && (
                  <p
                    className="cursor padding-none"
                    onClick={() => makeCardDefault(item)}
                  >
                    Set Default
                  </p>
                )}
              </div>
            </div>
            <br />
          </div>
        ))
      )}

      <div className="d-flex justify-content-end" style={{ marginTop: "10%" }}>
        <div className="d-flex align-items-center">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={() => history.push(ROUTES.widgetProfile)}
          >
            Go Back
          </p>
          <AddCard
            walletDetails={walletDetails}
            wallet={wallet}
            email={email}
            updatePayStackStatus={updatePayStackStatus}
            fetchWallet={fetchWallet}
            fetchAddedCards={fetchAddedCards}
          />
        </div>
      </div>
    </div>
  );
};

export default CardInformation;
