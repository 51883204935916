import React from "react";
import ButtonLoader from "../ButtonLoader/ButtonLoader.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { yupValidators } from "../../helpers/yupValidators";

const schema = yup.object().shape({
  newPassword: yupValidators.password,
  newPasswordAgain: yupValidators.password,
});
export default function ResetPasswordStepThree({ onSubmitStepThree, loading }) {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    const { newPasswordAgain, newPassword } = data;
    const reqBody = {
      newPassword,
      newPasswordAgain,
    };
    onSubmitStepThree(reqBody);
  };
  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-100 text-center mb-3">
        <small className="text-danger font-weight-bold ">
          Please enter your new password in the fields below
        </small>
      </div>
      <CustomInputField
        type="password"
        maxLength="128"
        reference={register}
        name="newPassword"
        placeholder="Password"
        label="Enter new password"
        errors={errors.newPassword}
        hasActionButton={true}
      />
      <CustomInputField
        type="password"
        maxLength="128"
        reference={register}
        name="newPasswordAgain"
        placeholder="Password again"
        label="Enter new password again"
        errors={errors.newPasswordAgain}
        hasActionButton={true}
      />
      <button
        type="submit"
        value="submit"
        className="btn btn-sm btn-primary btn-block mt-4"
        disabled={loading}
      >
        {loading ? <ButtonLoader /> : "Proceed"}
      </button>
    </form>
  );
}
