import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Input, InputNumber, message, Select } from "antd";
import { ROUTES } from "../../../../helpers/ROUTES";
import user from "../../../../assets/profile/user.svg";
import SetPinComponent from "../SetPinComponent";
import { SuccessComponent } from "../SuccessComponent";
import { WalletProp } from "../Dashboard/Dashboard.component";
import { getDataWithKey, postDataWithKey } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import CONFIG from "../../../../helpers/config";
import { errorHandler, errorToast } from "../../../../helpers/errorHandler";
import Loader from "../../../Loader/Loader";
import { appInsights } from "../../../AppInsight/AppInsight";

type Bank = {
  code: string;
  country_code: string;
  id: number;
  longcode: string;
  name: string;
  slug: string;
};
type WithdrawalProp = {
  wallet: {
    borrower_phone?: string;
    bvn?: string;
    customer_id?: number;
    first_name?: string;
    last_name?: string;
    user_id?: number;
    customer_category?: string;
    aggregator_id?: number;
    encryptedKey?: string;
  };
  bankList: Bank[];
  isApi?: boolean;
  handleDashboard?: () => void;
};

const WithdrawalComponent = ({
  wallet,
  bankList,
  isApi,
  handleDashboard,
}: WithdrawalProp) => {
  let location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [amount, setAmount] = useState("");
  const [withdrawalData, setWithdrawalData] = useState({
    recipient_bank_code: "",
    recipient_account_number: "",
    pin: "",
    comment: "",
  });
  const [accountName, setAccountName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [walletDetails, setWalletDetails] = useState<WalletProp | null>();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Withdrawal.component.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${wallet.customer_id}&aggregator_id=${wallet.aggregator_id}`,
        wallet.encryptedKey
      );
      setWalletDetails(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: WithdrawalComponent },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (wallet.customer_id && wallet.aggregator_id) {
      fetchWallet();
    }
  }, [wallet]);

  const fetchAccountName = async () => {
    setLoadingAccount(true);
    const token = localStorage.getItem("sso_auth_token");

    fetch(CONFIG.BASE_URL + "/account/validate_bank_account/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        bank_code: withdrawalData.recipient_bank_code,
        bank_account_num: withdrawalData.recipient_account_number,
      }),
    })
      .then((res) => {
        res.json().then((res) => {
          setAccountName(res.bank_account_name);
        });
        setLoadingAccount(false);
      })
      .catch((error) => {
        errorToast(errorHandler(error.message));
        setLoadingAccount(false);
      });
    // setLoadingAccount(false);
  };

  useEffect(() => {
    if (
      withdrawalData.recipient_bank_code !== "" &&
      withdrawalData.recipient_account_number?.length === 10
    ) {
      fetchAccountName();
    }
  }, [withdrawalData]);

  async function handleWithdraw() {
    setIsLoading(true);
    try {
      await postDataWithKey(
        apiEndpoints.withdrawFunds,
        {
          sender_account_number: walletDetails?.accountNumber,
          sender_wallet_id: walletDetails?.walletId,
          aggregator_id: wallet?.aggregator_id,
          borrower_id: wallet?.customer_id,
          recipient_account_number: withdrawalData?.recipient_account_number,
          recipient_bank_code: withdrawalData?.recipient_bank_code,
          amount: Number(amount),
          pin: withdrawalData?.pin,
          wallet_provider: walletDetails?.walletProviderId,
          comment: withdrawalData.comment,
        },
        wallet.encryptedKey
      );
      setShowPin(false);
      setShowSuccess(true);
    } catch (error) {
      message.error(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: WithdrawalComponent },
      });
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  if (showPin) {
    return (
      <SetPinComponent
        handleNext={() => {
          handleWithdraw();
        }}
        handleGoBack={() => setShowPin(false)}
        setValue={(value) =>
          setWithdrawalData({ ...withdrawalData, pin: value?.toString() })
        }
        label=""
      />
    );
  }

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Transaction Complete"
        body="You have successfully made a withdrawal from your wallet"
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }

  const withdrawAmount = () => {
    return (
      <div>
        <Form layout="vertical" onFinish={() => setShowBank(true)}>
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            initialValue={amount}
            name="amount"
            label="Withdrawal Amount"
            rules={[{ required: true, message: "Required field" }]}
          >
            {/*<Input onChange={(e) => setAmount(e.target.value)} />*/}
            <div>
              <InputNumber
                size="large"
                formatter={(value) =>
                  ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                onChange={(amount) => setAmount(String(amount))}
                style={{ width: "100%" }}
                value={amount}
              />
            </div>
          </Form.Item>

          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "40%" }}
          >
            <div className="d-flex align-items-center">
              <p
                className="text-primary padding-none mr-3 cursor-pointer"
                onClick={() =>
                  isApi && handleDashboard
                    ? handleDashboard()
                    : history.push(ROUTES.widgetDashboard)
                }
              >
                Go Back
              </p>
              <button className="btn btn-primary btn-radius">Next</button>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  const bank = () => {
    return (
      <div>
        <p className="padding-none">Where would you like to withdraw into?</p>
        <Form layout="vertical" form={form} onFinish={() => setShowPin(true)}>
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            initialValue=""
            name="recipient_bank_code"
            label="Bank Name"
            rules={[{ required: true, message: "Required field" }]}
          >
            <Select
              placeholder="Select"
              allowClear
              onChange={(value) =>
                setWithdrawalData({
                  ...withdrawalData,
                  recipient_bank_code: value,
                })
              }
            >
              {bankList.map((item) => (
                <Select.Option value={item.code}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            initialValue=""
            name="recipient_account_number"
            label="Account Number"
            rules={[
              { required: true, message: "Required field" },
              {
                min: 10,
                message: "Account number must be 10 digits",
              },
            ]}
          >
            <Input
              onChange={(e) =>
                setWithdrawalData({
                  ...withdrawalData,
                  recipient_account_number: e.target.value,
                })
              }
            />
          </Form.Item>

          <div className="d-flex align-items-center">
            <img
              src={user}
              className="img-fluid mr-2"
              alt=""
              style={{
                width: "11.48px",
                height: "13.2px",
                objectFit: "contain",
              }}
            />
            <small className="color-mid-purple account__name">
              {loadingAccount ? "Loading...." : accountName}
            </small>
          </div>

          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            initialValue=""
            name="narration"
            label="Narration"
            rules={[{ required: true, message: "Required field" }]}
          >
            <Input
              onChange={(e) =>
                setWithdrawalData({
                  ...withdrawalData,
                  comment: e.target.value,
                })
              }
            />
          </Form.Item>

          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "40%" }}
          >
            <div className="d-flex align-items-center">
              <p
                className="text-primary padding-none mr-3 cursor-pointer"
                onClick={() => setShowBank(false)}
              >
                Go Back
              </p>
              <button
                className="btn btn-primary btn-radius"
                disabled={isLoading}
              >
                Next
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <div>
      <h4>Withdraw</h4>
      {!showBank ? withdrawAmount() : bank()}
    </div>
  );
};

export default WithdrawalComponent;
