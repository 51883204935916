import React, { useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";
import { BiRightArrowAlt, IoMdArrowRoundBack } from "react-icons/all";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import wallet from "../../../../../../assets/loans/wallet.svg";
import repay from "../../../../../../assets/loans/repay.svg";
import styles from "../../../../WidgetFlow.module.scss";
import { deviceType } from "react-device-detect";
import { appInsights } from "../../../../../AppInsight/AppInsight";

type TLocation = {
  state: {
    loan_id: number;
  };
};

const LoanRepayment = () => {
  let location = useLocation();

  const {
    state: { loan_id },
  } = location as TLocation;

  const history = useHistory();
  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRepayment.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <div
        onClick={() => history.push(ROUTES.widgetDashboard)}
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Loan Repayment</h4>
      <br />
      <div
        className="card cursor bg-primary"
        style={{ height: "108px" }}
        onClick={() => {
          history.push({
            pathname: ROUTES.widgetLoanRepaymentAmount,
            state: { loan_id: loan_id },
          });
        }}
      >
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              className={`${
                deviceType === "mobile" ? styles.iconMobile : styles.icon
              } icon__primary mr-3`}
              style={{ borderRadius: "10px" }}
            >
              <img
                src={repay}
                alt=""
                className="img-fluid"
                style={{ width: "11.48px", height: "13.2px" }}
              />
            </div>
            <div>
              <p className="font-weight-bold text-white padding-none">
                Offline Repayment
              </p>
              <small className="text-white padding-none"></small>
            </div>
          </div>

          <BiRightArrowAlt className="text-white" size="20px" />
        </div>
      </div>
      <br />

      <div
        className="card cursor"
        style={{ height: "108px" }}
        onClick={() => {
          history.push({
            pathname: ROUTES.widgetLoanRepaymentMpesa,
            state: {
              loan_id,
            },
          });
        }}
      >
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              className={`${
                deviceType === "mobile" ? styles.iconMobile : styles.icon
              } icon__primary mr-3`}
              style={{ backgroundColor: "#9DA9B3", borderRadius: "10px" }}
            >
              <img
                src={wallet}
                alt=""
                className="img-fluid"
                style={{ width: "11.48px", height: "13.2px" }}
              />
            </div>
            <div>
              <p className="font-weight-bold text-primary padding-none">
                Pay via Mpesa
              </p>
              <small className=" padding-none"></small>
            </div>
          </div>

          <BiRightArrowAlt className="" size="20px" />
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default LoanRepayment;
