export const apiEndpoints = {
  resetPasswordUrl: `/account/reset_password/`,
  loginUrl: `/account/custom_login/`,
  changePasswordUrl: `/account/change_password/`,
  makePaymentFromWidget: `/eco/aggr_repay_loan_key`,
  getBorrowerLoansInWidget: `/eco/my_boro_loan_key`,
  getProductByCode: `/misc/query_product_by_code`,
  validateBankAccount: `/account/validate_bank_account/`,
  updateBorrowerLoanStatusWithKey: `/eco/update_borrower_loan_status_key/`,
  getBorrowerProfile: `/account/my_boro_profile/`,
  checkOkraPaystackOnAccount: `/account/okra_paystack_details`,
  getAggregatorKey: `/account/get_aggregator_keys`,
  getBorrowerLoanRepayments: `/eco/boro_man_repayment`,
  paystackInfo: `eco/widget_card`,
  getWallet: "/eco/widget_default_wallet",
  getTransactions: "/eco/widget_default_wallet_transactions",
  getSecurityQuestions: "/account/widget_security_question/all",
  saveSecurityQuestionAndAnswer: "/account/widget_security_question/answers",
  saveCustomerPin: "/account/widget_customer_pin",
  resetCustomerPin: "/account/widget_reset_customer_pin",
  withdrawFunds: "/account/widget_withdraw_funds",
  getLoanProducts: "/misc/query_product_key",
  processLoan: "/eco/process_borrower_loan_key",
  getLoanRepaymentSchedule: "/eco/widget_loan_repayments_schedule",
  makeRepayment: "/eco/widget_initiate_repayment",
  addCard: "/eco/widget_add_card",
  fetchCards: "/eco/widget_get_cards",
  setDefaultCard: "/eco/widget_set_default_card",
  getRepayments: "/eco/widget_loan_repayments",
  generateLoanDetails: "/eco/widget_generate_loan_schedule",
  getWidgetSettings: "/account/widget_aggregator_settings",
  getCollectionAccounts: "/collectionaccount/widget_search",
  offlineRepayment: "/eco/widget_manual_loan_repayment",
  checkCustomerExist: "/account/widget_check_customer_exist",
  saveBankStatementProvider: "/eco/widget_bank_statement_provider",
  contactUs: "/misc/widget_contact_us_form",
  checkBankProviderLinked: "/eco/widget_validate_bank_provider_linked",
  postLoanRepaymentViaMpesa: "/eco/widget_mpesa_loan_repayment",
};
