import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./Dashboard.module.scss";
import refresh from "../../../../assets/dashboard/refresh.svg";
import {
  formatCurrency,
  formatMoney,
} from "../../../../helpers/formatCurrency";
import { AiOutlineLock, RiFileCopyFill } from "react-icons/all";
import topup from "../../../../assets/dashboard/coin-fill.svg";
import withdraw from "../../../../assets/dashboard/send-plane-fill.svg";
import loans from "../../../../assets/dashboard/loans.svg";
import profile from "../../../../assets/dashboard/user.svg";
import contact from "../../../../assets/dashboard/headphone.png";
import Loader from "../../../Loader/Loader";
import { ROUTES } from "../../../../helpers/ROUTES";
import {
  getDataWithKey,
  putDotNetDataWithKey,
} from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { errorHandler, errorToast } from "../../../../helpers/errorHandler";
import { SuccessComponent } from "../SuccessComponent";
import copy from "copy-text-to-clipboard";
import AppCard from "../components/AppCard";
import { appInsights } from "../../../AppInsight/AppInsight";
import { CurrencyProp } from "../types";
import moment from "moment";
import Okra from "okra-js";
import { Button, message } from "antd";
import CONFIG from "../../../../helpers/config";
import MonoConnect from "@mono.co/connect.js";

export type UserProp = {
  wallet: {
    bvn_number?: string;
    country_code?: string;
    business_registration_number?: string;
    borrower_phone?: string;
    customer_id?: number;
    first_name?: string;
    last_name?: string;
    user_id?: number;
    customer_category?: string;
    aggregator_id?: number;
    encryptedKey?: string;
  };
  first_name: string;
  email?: string;
  customer_type?: string;
  company_name?: string;
  updatePayStackStatus?: () => void;
  updateBankStatementWidgetStatus?: () => void;
  deviceType: "mobile" | "browser";
  isApi?: boolean;
  handleWithdrawal?: () => void;
  handleDashboard?: () => void;
  handlePin?: () => void;
  currency: CurrencyProp;
  state: any;
  useWallet: boolean;
  allowContactUs: boolean;
  bank_statement_connected?: boolean;
  bank_statement_provider?: string;
  updateBankStatementStatus?: () => void;
};

export type WalletProp = {
  customerId: number;
  walletProvider: string;
  walletProviderId: string;
  bankName: string;
  currency: string;
  accountNumber: string;
  walletId: string;
  accountName: string;
  isDefault: boolean;
  availableBalance: number;
  ledgerBalance: number;
};
const DashboardComponent = ({
  wallet,
  first_name,
  customer_type,
  company_name,
  deviceType,
  isApi,
  handleWithdrawal,
  handlePin,
  currency,
  useWallet,
  updateBankStatementWidgetStatus,
  bank_statement_provider,
  bank_statement_connected,
  allowContactUs,
  updateBankStatementStatus,
}: UserProp) => {
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingProvider, setIsSavingProvider] = useState(false);
  const [walletDetails, setWalletDetails] = useState<WalletProp | null>();
  const [cardAddition, setCardAddition] = useState("");
  const [lastLoan, setLastLoan] = useState({
    loan_status: "",
    modified_date: "",
    loan_amount: 0,
  });
  const [loanCurrency, setLoanCurrency] = useState("");

  async function fetchLoanHistory() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getBorrowerLoansInWidget}?bvn=${
          wallet.bvn_number
        }&aggregator_domain=${window.location.origin}&aggregator_id=${Number(
          wallet.aggregator_id
        )}`,
        wallet.encryptedKey
      );
      if (response.data.length > 0) {
        setLoanCurrency(response.data[0]?.currency);
        setLastLoan(response.data[0]);
      }
    } catch (error: any) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Dashboard.component.tsx" },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    console.log({ useWallet, allowContactUs });
    fetchLoanHistory();
  }, [useWallet]);
  function handleRefresh() {
    fetchWallet();
  }

  useEffect(() => {
    appInsights.trackPageView({
      name: "Dashboard.component.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${wallet.customer_id}&aggregator_id=${wallet.aggregator_id}`,
        wallet.encryptedKey
      );
      setWalletDetails(response.data);
    } catch (error: any) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: DashboardComponent },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (wallet?.customer_id && wallet?.aggregator_id) {
      fetchWallet();
    }
    if (localStorage.getItem("card")) {
      setCardAddition(localStorage.getItem("card") || "");
      localStorage.removeItem("card");
    }
  }, [wallet]);

  const handleCopy = () => {
    const content = document?.getElementById("accountNumber")?.textContent;
    if (content) {
      copy(content);
    }
  };

  function handleLaunchOkraWidget() {
    if (!CONFIG.OKRA_PUBLIC_KEY || !CONFIG.OKRA_CLIENT_TOKEN) {
      return message.error("Update Config for Okra");
    }
    if (updateBankStatementWidgetStatus) {
      updateBankStatementWidgetStatus();
    }
    Okra.buildWithOptions({
      name: "ADVANCLY",
      env: CONFIG.OKRA_ENV,
      app_id: "", // app_id from your app builder
      key: CONFIG.OKRA_PUBLIC_KEY,
      token: CONFIG.OKRA_CLIENT_TOKEN,
      products: ["auth", "transactions"], //others are ['auth','identity','balance','transactions', 'income'](in lowercase)
      onSuccess: function (data: any) {
        console.log("options success", data);
        handleSaveCustomerCode(data?.customer_id, "okra");
      },
      onClose: function () {
        console.log("options close");
      },
    });
  }

  const monoConnect = React.useMemo(() => {
    if (!CONFIG.MONO_PUBLIC_KEY) {
      return message.error("Update Config for Mono");
    }
    if (updateBankStatementWidgetStatus) {
      updateBankStatementWidgetStatus();
    }
    const monoInstance = new MonoConnect({
      onClose: () => console.log("Widget closed"),
      onLoad: () => console.log("Widget loaded successfully"),
      key: CONFIG.MONO_PUBLIC_KEY,
      onSuccess: async (data: { code: string }) => {
        handleSaveCustomerCode(data?.code, "mono");
      },
    });

    monoInstance.setup();

    return monoInstance;
  }, []);

  const handleSaveCustomerCode = async (
    code: string,
    provider: "okra" | "mono"
  ) => {
    //Call d api to store the code
    console.log({ customerId: code });
    const reqBody = {
      bank_statement_provider_id: provider === "okra" ? 1 : 2,
      bank_statement_provider_customer_code: provider === "okra" ? code : "",
      authentication_code: provider === "mono" ? code : "",
      customer_id: wallet.customer_id,
      aggregator_id: wallet.aggregator_id,
    };
    try {
      setIsSavingProvider(true);
      await putDotNetDataWithKey(
        apiEndpoints.saveBankStatementProvider,
        reqBody,
        wallet.encryptedKey
      );
      message.success("Bank provider details saved successfully");
      if (updateBankStatementStatus) {
        updateBankStatementStatus();
      }
      history.push(ROUTES.widgetLoan);
    } catch (error) {
      console.log(error);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Dashboard.component.tsx" },
      });
      message.error("Something went wrong please try again");
    } finally {
      setIsSavingProvider(false);
    }
  };

  if (cardAddition === "added") {
    return (
      <SuccessComponent
        btnText="Done"
        body="Your card was added successfully"
        title="Success!"
        handler={() => history.push(ROUTES.widgetCard)}
        type="success"
      />
    );
  }

  if (cardAddition === "failed") {
    return (
      <SuccessComponent
        btnText="Ok"
        body="Something went wrong adding your card"
        title="Failed!"
        handler={() => history.push(ROUTES.widgetCard)}
        type="failure"
      />
    );
  }

  return (
    <div className={styles.dashboard}>
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <h5 className={styles.title}>
          Hello{" "}
          <small className="font-weight-bold">
            {customer_type === "2" ? company_name : first_name}👋🏼
          </small>
        </h5>
        <div className="d-flex">
          <div>
            {/*<small className="text-muted">NGN</small>*/}
            <small>
              <i className="fa fa-chevron-down text-dark ml-2" />
            </small>
          </div>
          {!isApi && (
            <div className="ml-5">
              <small>
                <i
                  className="fa fa-bell text-muted ml-2"
                  style={{ fontSize: "20px" }}
                />
              </small>
            </div>
          )}
        </div>
      </div>
      {!isApi && (
        <div className="d-flex justify-content-between flex-wrap">
          <p className="padding-none">Customer category</p>

          <p className="text-dark">{wallet?.customer_category}</p>
        </div>
      )}
      {currency === "NGN" && useWallet ? (
        <>
          <br />
          <div className="d-flex justify-content-center">
            <div
              className={
                deviceType === "mobile"
                  ? styles.walletCardMobile
                  : styles.walletCard
              }
            >
              {isLoading ? (
                <Loader light />
              ) : (
                <div>
                  <div className="d-flex align-items-center">
                    <p className="text-white mr-2">Wallet Balance</p>
                    <img
                      onClick={handleRefresh}
                      src={refresh}
                      className="img-fluid"
                      alt=""
                      style={
                        deviceType === "mobile"
                          ? { height: "3rem", width: "3rem", cursor: "pointer" }
                          : { height: "20px", width: "20px", cursor: "pointer" }
                      }
                    />
                  </div>

                  <div>
                    <h3 className="text-white text-center font-weight-bold">
                      <span className="mr-1 font-weight-bold">&#8358;</span>
                      {formatCurrency(walletDetails?.availableBalance || 0)}
                    </h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
      <br />
      {currency === "NGN" && useWallet && walletDetails?.accountNumber ? (
        <>
          <p className="text-muted mb-1">Your account number</p>
          <AppCard
            className="d-flex justify-content-between"
            handleCopy={handleCopy}
          >
            <div className="d-flex align-items-center">
              <p
                id="accountNumber"
                className="font-weight-bold mr-1 text-primary-dark"
              >
                {walletDetails?.accountNumber}
              </p>
              <div style={{ marginTop: "-6px" }} className="cursor-pointer">
                <RiFileCopyFill className="text-primary-dark" />
              </div>
            </div>
            <p className="text-primary-dark">{walletDetails?.accountName}</p>
            <p className="text-primary-dark" style={{ color: "#200865" }}>
              {walletDetails?.bankName}
            </p>
          </AppCard>
        </>
      ) : null}

      {currency === "KSH" ? (
        <AppCard
          className="d-flex justify-content-between"
          handleCopy={() => null}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <div className="d-flex flex-wrap w-100 align-items-center justify-content-between">
              <div style={{ maxWidth: "100%" }}>
                <p className="text-break">Last Requested Loan</p>
                <p
                  id="accountNumber"
                  className="font-weight-bold mr-1 text-primary-dark"
                >
                  {formatMoney(lastLoan.loan_amount, loanCurrency)}
                </p>
                {lastLoan.modified_date && (
                  <p>{moment(lastLoan.modified_date).format("LLL")}</p>
                )}

                <p id="loan_status" className="mr-1">
                  <i>{lastLoan.loan_status}</i>
                </p>
              </div>

              <div>
                <Button
                  size="middle"
                  type="primary"
                  onClick={() => history.push(ROUTES.widgetLoanHistory)}
                  disabled={
                    !lastLoan.loan_status || lastLoan.loan_status !== "active"
                  }
                >
                  Repay Loan
                </Button>
              </div>
            </div>
          )}
        </AppCard>
      ) : null}

      <br />
      <div className="d-flex justify-content-between align-items-center mb-2">
        <p className="text-dark" onClick={() => window.alert(deviceType)}>
          Quick Actions
        </p>
        {currency === "NGN" && useWallet && !isApi && (
          <small
            className="text-primary-dark padding-none"
            style={{ cursor: "pointer" }}
            onClick={() => history.push(ROUTES.widgetTransactions)}
          >
            View Transactions
          </small>
        )}
      </div>
      <div className="d-flex justify-content-between">
        {isApi && (
          <div
            className={`${
              deviceType === "mobile" ? styles.cardMobile : styles.card
            } card mb-3`}
            onClick={handlePin}
          >
            <div className="card-body d-flex align-items-center justify-content-start h-100 w-100">
              <div className="d-flex align-items-center justify-content-start">
                <div
                  className="icon icon__primary"
                  style={{ background: "#200865" }}
                >
                  <AiOutlineLock
                    className="text-white"
                    size="20px"
                    style={{ marginTop: "-7px" }}
                  />
                </div>
                <small className="text-dark ml-2">Transaction Pin</small>
              </div>
            </div>
          </div>
        )}
        {currency === "NGN" && useWallet && !isApi && (
          <div
            className={`${
              deviceType === "mobile" ? styles.cardMobile : styles.card
            } card mb-3`}
            onClick={() => history.push(ROUTES.widgetTopUp)}
          >
            <div className="card-body d-flex align-items-center justify-content-start h-100 w-100">
              <div className="d-flex align-items-center justify-content-start">
                <div className="icon icon__green">
                  <img src={topup} alt="" className="img-fluid" />
                </div>
                <small className="text-dark ml-2">Top-up</small>
              </div>
            </div>
          </div>
        )}

        {((currency === "NGN" && useWallet) || isApi) && (
          <div
            className={`${
              deviceType === "mobile" ? styles.cardMobile : styles.card
            } card mb-3`}
            onClick={() =>
              isApi && handleWithdrawal
                ? handleWithdrawal()
                : history.push(ROUTES.widgetWithdrawal)
            }
          >
            <div className="card-body d-flex align-items-center justify-content-start h-100 w-100">
              <div className="d-flex align-items-center justify-content-start">
                <div className="icon icon__lightOrange">
                  <img src={withdraw} alt="" className="img-fluid" />
                </div>
                <small className="text-dark ml-2">Withdraw</small>
              </div>
            </div>
          </div>
        )}
      </div>

      {!isApi &&
        (isSavingProvider ? (
          <Loader />
        ) : (
          <div className="d-flex justify-content-between">
            <div
              className={`${
                deviceType === "mobile" ? styles.cardMobile : styles.card
              } card`}
              onClick={() => {
                if (!bank_statement_connected) {
                  if (bank_statement_provider?.toLowerCase() === "okra") {
                    handleLaunchOkraWidget();
                  } else {
                    monoConnect.open();
                  }
                } else {
                  history.push(ROUTES.widgetLoan);
                }
              }}
            >
              <div className="card-body d-flex align-items-center justify-content-start h-100 w-100">
                <div className="d-flex align-items-center justify-content-start">
                  <div className="icon icon__primary">
                    <img src={loans} alt="" className="img-fluid" />
                  </div>
                  <small className="text-dark ml-2">Loans</small>
                </div>
              </div>
            </div>

            <div
              className={`${
                deviceType === "mobile" ? styles.cardMobile : styles.card
              } card`}
              onClick={() => history.push(ROUTES.widgetProfile)}
            >
              <div className="card-body d-flex align-items-center justify-content-start h-100 w-100">
                <div className="d-flex align-items-center justify-content-start">
                  <div className="icon icon__secondary">
                    <img src={profile} alt="" className="img-fluid" />
                  </div>
                  <small className="text-dark ml-2">Profile</small>
                </div>
              </div>
            </div>
          </div>
        ))}

      {allowContactUs ? (
        <div
          className={`${
            deviceType === "mobile" ? styles.cardMobile : styles.card
          } card mt-3`}
          onClick={() => history.push(ROUTES.widgetContactUs)}
        >
          <div className="card-body d-flex align-items-center justify-content-start h-100 w-100">
            <div className="d-flex align-items-center justify-content-start">
              <div className="icon icon__primary">
                <img src={contact} alt="" className="img-fluid w-50" />
              </div>
              <small className="text-dark ml-2">Contact Us</small>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DashboardComponent;
