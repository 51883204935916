import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/all";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import logo from "../../../../../../assets/loans/loanHistory.svg";
import { formatMoney } from "../../../../../../helpers/formatCurrency";
import moment from "moment";
import LoanRepaymentSchedule from "./LoanRepaymentSchedule";
import LoanRepayments from "./LoanRepayments";
import { LoanProp } from "./LoanHistory";
import { accurateQuantifier } from "../../../../../../helpers/accurateQuantifier";
import { appInsights } from "../../../../../AppInsight/AppInsight";

const LoanHistoryDetails = ({
  loan_amount,
  loan_id,
  loan_effective_date,
  loan_due_date,
  loan_interest_amount,
  loan_amount_repay,
  loan_tenure,
  loan_outstanding,
  loan_status,
  status,
  loan_ref,
  pub_date,
  loan_repayment_balance,
  modified_date,
  has_repaid,
  has_disburse,
  borrower_id,
  encryptedKey,
  aggregator_id,
  onCancel,
  currency,
}: LoanProp) => {
  let location = useLocation();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [showSchedule, setShowSchedule] = useState(false);
  const [showRepayment, setShowRepayment] = useState(false);

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanHistoryDetails.tsx",
      isLoggedIn: true,
    });
  }, []);

  if (showSchedule) {
    return (
      <LoanRepaymentSchedule
        encryptedKey={encryptedKey}
        aggregator_id={aggregator_id}
        loan_ref={loan_ref}
        onCancel={() => {
          setShowSchedule(false);
        }}
        currency={currency || ""}
      />
    );
  }

  if (showRepayment) {
    return (
      <LoanRepayments
        borrower_id={borrower_id}
        encryptedKey={encryptedKey}
        aggregator_id={aggregator_id}
        loan_ref={loan_ref}
        onCancel={() => setShowRepayment(false)}
        currency={currency || ""}
      />
    );
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div onClick={() => (onCancel ? onCancel() : null)} className="cursor">
          <IoMdArrowRoundBack className="cursor__back" />
        </div>

        <div className="d-flex align-items-center ">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={() => {
              setShowSchedule(false);
              setShowRepayment(true);
            }}
          >
            View repayments
          </p>
          <button
            className="btn btn-primary btn-radius"
            onClick={() => {
              history.push({
                pathname: ROUTES.widgetLoanRepaymentOptionView,
                state: { loan_id: loan_id },
              });
            }}
            disabled={loan_status !== "active"}
          >
            Repay loan
          </button>
        </div>
      </div>
      <br />

      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className=" mr-3">
              <img
                src={logo}
                alt=""
                className="img-fluid"
                style={{ width: "40px", height: "40px" }}
              />
            </div>
            <div>
              <p className="font-weight-bold text-dark padding-none">
                {formatMoney(loan_amount || 0, currency || "")}
                <small
                  className={`ml-2 ${
                    loan_status?.toLowerCase() === "active"
                      ? " text-warning"
                      : "text-success"
                  }`}
                >
                  {loan_status}
                </small>
              </p>
              <small className="text-muted padding-none">
                {moment(pub_date).format("D/MM/YYYY, h:mm a")}
              </small>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-dark">
                {loan_id}
              </p>
              <p className="font-weight-bold padding-none text-dark">
                {accurateQuantifier(loan_tenure, "day")}
              </p>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">ID</p>
              <p className=" padding-none text-muted">Tenure</p>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-dark">
                {moment(pub_date).format("DD MMM, YYYY")}
              </p>
              <p className="font-weight-bold padding-none text-dark">
                {loan_ref}
              </p>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">Request date</p>
              <p className=" padding-none text-muted">Reference Number</p>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-success">
                {has_disburse ? "Disbursed" : "Pending"}
              </p>
              <p className="font-weight-bold padding-none text-dark">
                {moment(modified_date).format("DD MMM, YYYY")}
              </p>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">Disbursement Status</p>
              <p className=" padding-none text-muted">Approval date</p>
            </div>
          </div>
          <hr />

          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-dark">
                {formatMoney(loan_amount || 0, currency || "")}
              </p>
              <p className="font-weight-bold padding-none text-danger">
                {moment(loan_due_date).format("DD MMM, YYYY")}
              </p>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">Amount</p>
              <p className=" padding-none text-muted">Due date</p>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-dark">
                {formatMoney(loan_interest_amount || 0, currency || "")}
              </p>
              <p className="font-weight-bold padding-none text-dark">
                {formatMoney(loan_outstanding || 0, currency || "")}
              </p>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">Interest</p>
              <p className=" padding-none text-muted">Outstanding</p>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-weight-bold padding-none text-dark">
                {formatMoney(
                  Number(loan_amount || 0) +
                    Number(loan_interest_amount || 0) || 0,
                  currency || ""
                )}
              </p>
              <p className="font-weight-bold padding-none text-dark">
                {formatMoney(loan_amount_repay || 0, currency || "")}
              </p>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className=" padding-none text-muted">Total Repayment Amount</p>
              <p className=" padding-none text-muted">Amount repaid</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <p
        onClick={() => {
          setShowSchedule(true);
          setShowRepayment(false);
        }}
        className="text-primary text-center cursor"
      >
        View repayments schedule
      </p>
    </div>
  );
};

export default LoanHistoryDetails;
