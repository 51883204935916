import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { formatCurrency } from "../../../../../../helpers/formatCurrency";
import { StepProp } from "./LoanRequest";
import { appInsights } from "../../../../../AppInsight/AppInsight";

const LoanRequestStepThree = ({
  handleNext,
  handleGoBack,
  processedLoanData,
}: StepProp) => {
  let location = useLocation();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRequestStepThree.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <>
      <h4>Loan Offer</h4>
      <p className="text-muted">
        Based on the selected loan product, you are eligible for this offer.
      </p>

      <div
        className="card"
        style={{ backgroundColor: "#F4F5F6", borderRadius: "10px" }}
      >
        <div
          className="card-header"
          style={{ backgroundColor: "#F4F5F6", borderRadius: "10px" }}
        >
          <h4>{processedLoanData?.product_name}</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <p className="text-muted padding-none">Loan Amount</p>
              <p className="text-dark padding-none font-weight-bolder">
                {`${processedLoanData?.currency.code}${formatCurrency(
                  processedLoanData?.totalPrincipalDisbursed || 0
                )}`}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-muted padding-none">Loan Tenure</p>
              <p className="text-dark padding-none font-weight-bolder">
                {`${processedLoanData?.loanTermInDays} days`}
              </p>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-6">
              <p className="text-muted padding-none">Loan Payback</p>
              <p className="text-dark padding-none font-weight-bolder">
                {`${processedLoanData?.currency.code}${formatCurrency(
                  processedLoanData?.totalRepaymentExpected || 0
                )}`}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-muted padding-none">Loan Interest</p>
              <p className="text-dark padding-none font-weight-bolder">
                {`${processedLoanData?.currency.code}${formatCurrency(
                  processedLoanData?.totalInterestCharged || 0
                )}`}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end" style={{ marginTop: "20%" }}>
        <div className="d-flex align-items-center">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={handleGoBack}
          >
            Go Back
          </p>
          <button className="btn btn-primary btn-radius" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default LoanRequestStepThree;
