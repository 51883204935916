import React from "react";
import ButtonLoader from "../ButtonLoader/ButtonLoader.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { yupValidators } from "../../helpers/yupValidators";

const schema = yup.object().shape({
  otp: yupValidators.otp,
});
export default function ResetPasswordStepTwo({ onSubmitStepTwo, loading }) {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    const { otp } = data;
    const reqBody = {
      otp,
    };
    onSubmitStepTwo(reqBody);
  };
  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-100 text-center mb-3">
        <small className="text-danger font-weight-bold ">
          An OTP has been sent to your email address
        </small>
      </div>
      <CustomInputField
        type="number"
        maxLength="10"
        reference={register}
        name="otp"
        placeholder="OTP"
        label="Please enter OTP"
        errors={errors.otp}
      />
      <button
        type="submit"
        value="submit"
        className="btn btn-sm btn-primary btn-block mt-4"
        disabled={loading}
      >
        {loading ? <ButtonLoader /> : "Proceed"}
      </button>
    </form>
  );
}
