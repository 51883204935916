import React, { Component } from "react";
import { Link, Route, withRouter } from "react-router-dom";
import CONFIG from "../helpers/config";
import { appHelpers } from "../helpers/appHelpers";

// import advanclyLogo from "../assets/advancly-logo.png";

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: true,
      state_token: "",
      first_name: "",
      last_name: "",
      aggregator_id: "",
      aggregatorList: [],
      redirectToStep2: false,
      // bankList will throw error if there's no network, put dummy data as placeholder
      bankList: [
        {
          code: "0",
          id: 1,
          longcode: "0",
          name: "Please check your network connection",
          slug: "error",
        },
      ],
    };
  }

  upadateState = (state) => {
    this.setState(state);
  };

  componentDidMount() {
    this.getAggregatorList();
    this.getBankList();
  }

  getAggregatorList = () => {
    fetch(CONFIG.BASE_URL + "/account/advancly_aggregators/", {
      method: "GET",
    })
      .then((response) => {
        this.setState({ loading: false });

        if (response.status === 200 || response.status === 201) {
          response.json().then((res) => {
            this.setState({ aggregatorList: res.data });
          });
        }

        if (response.status === 400) {
          response.json().then((res) => {
            this.setState({ error: res.message, notify: false });
          });
        }

        if (response.status >= 500) {
          this.setState({
            error: "Unable to connect to Advancly services",
            notify: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: "Unable to connect to Advancly services",
          notify: false,
        });
      });
  };

  getBankList = () => {
    return new Promise((resolve, reject) => {
      fetch(CONFIG.BASE_URL + "/account/signed_banks", {
        method: "GET",
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            response.json().then((res) => {
              this.setState({ bankList: res.data });
              resolve();
            });
          }

          if (response.status === 400) {
            response.json().then((res) => {
              this.setState({ error: res.message, notify: false });
              reject();
            });
          }

          if (response.status >= 500) {
            this.setState({
              error: "Unable to connect to Advancly services ",
              notify: false,
            });
            reject();
          }
        })
        .catch(() => {
          this.setState({
            error: "Unable to connect to Advancly services.",
            notify: false,
          });
          reject();
        });
    });
  };

  render() {
    return (
      <div>
        <div className="container space-2">
          {this.state.error && (
            <p className="alert alert-danger">{this.state.error}</p>
          )}
          <div>
            {this.state.loading ? (
              <div className="d-flex justify-content-center">
                <span className="spinner-border spinner-border-md text-primary"></span>
              </div>
            ) : (
              <div>
                <div className="d-flex justify-content-end mb-4">
                  <Link to="/login">
                    <button className="btn btn-sm btn-primary" type="button">
                      Back to Login
                    </button>
                  </Link>
                </div>
                <div className="row justify-content-center">
                  <Route
                    exact
                    path="/create-account"
                    render={() => (
                      <Step1WithRouter
                        upadateState={this.upadateState}
                        aggregatorList={this.state.aggregatorList}
                        redirectToStep2={this.state.redirectToStep2}
                      />
                    )}
                  />
                  {this.props.location.pathname !==
                    "/create-account/details" && (
                    <Route
                      exact
                      path="/create-account/:id"
                      render={() => (
                        <Step1WithRouter
                          upadateState={this.upadateState}
                          aggregatorList={this.state.aggregatorList}
                          redirectToStep2={this.state.redirectToStep2}
                        />
                      )}
                    />
                  )}

                  <Route
                    exact
                    path="/create-account/details"
                    render={() => <Step2WithRouter res={this.state} />}
                  />

                  {/* {this.state.redirectToStep2 && <Route exact path="/create-account/details"
                                        render={() => <Step2WithRouter res={this.state} />} />
                                    } */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      notify: false,
      bvnValid: false,
      bvnIconClass: "fas fa-plus",
      otpIconClass: "fas fa-plus",
      borrowerType: "1", // 1 is individual, 2 is corporate
      bvn: "",
      bvn_phone: "",
      email: "",
      aggregator: "",
      company_name: "",
      cac_number: "",
      otp: "",
      duration: 2000 * 60,
      minutes: 0,
      seconds: 0,
      timerExpired: false,
      cacValidated: false,
      bvnValidated: false,
      emailValidated: false,
      bvnIconStatus: "editing",
      otpIconStatus: "editing",
      state_token: "",
      redirectToStep2: props.redirectToStep2,
    };
    // const test = ""
  }
  componentDidMount() {
    if (
      this.props.match.params.id &&
      this.props.match.params.id !== "details"
    ) {
      localStorage.setItem("agg_short_name", this.props.match.params.id);
      this.getAggregatorId();
    } else {
      localStorage.removeItem("agg_short_name");
    }
  }

  countDown = () => {
    this.setState({ duration: 2000 * 60 });
    this.test = setInterval(() => {
      this.secondPass();
    }, 1000);
    return this.test;
  };

  secondPass = () => {
    if (this.state.duration === 0) {
      this.setState({ timerExpired: true });
      clearInterval(this.test);
      return;
    } else {
      let remainingTime = this.state.duration;
      let remMinutes = Math.floor(remainingTime / (1000 * 60)); // To Determine The Minutes
      let remSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000); // To Determine The Seconds
      this.setState({
        duration: remainingTime - 1000,
        minutes: remMinutes,
        seconds: remSeconds,
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "borrowerType") {
      this.setState({
        error: false,
        email: "",
        [name]: value,
        bvnValidated: false,
        bvn: "",
        cacValidated: false,
        emailValidated: false,
        bvnLoading: false,
        cac_number: "",
        company_name: "",
      });
    }

    if (name === "company_name" || name === "cac_number") {
      this.setState({
        error: false,
        cacValidated: false,
        email: "",
        [name]: value,
        bvnValidated: false,
        bvn: "",
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleEmailChange = (e) => {
    this.setState({
      error: false,
      emailValidated: true,
      bvnValidated: false,
      bvn: "",
    });
    let email = e.target.value;
    this.setState({ email: email }, () => {
      if (!appHelpers.validateEmail(email)) {
        this.setState({
          emailValidated: false,
          error: `Please enter a valid email address`,
          notify: false,
        });
        return;
      }
    });
  };

  handleCacValidation = (e) => {
    e.preventDefault();
    e.persist();

    const { company_name, cac_number, aggregator } = this.state;

    this.setState({ error: false, loading: true });

    // validate fields
    if (company_name === "") {
      this.setState({
        loading: false,
        error: `Please enter your Business Name`,
        notify: false,
      });
      return;
    }
    if (cac_number === "") {
      this.setState({
        loading: false,
        error: `Please enter your CAC number`,
        notify: false,
      });
      return;
    }

    // data to be sent to endpoint
    let data = {
      aggregator_id: Number(aggregator),
      company_name: company_name,
      cac_number: cac_number,
      board_step: "first_step",
    };

    // POST
    fetch(CONFIG.BASE_URL + "/account/direct_onboard_corporate_borrower/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          response.json().then((res) => {
            this.setState({
              loading: false,
              notify: `${res.message}`,
              cacValidated: true,
              state_token: `${res.state_token}`,
            });
          });
        }
        if (response.status === 400) {
          response.json().then((res) => {
            this.setState({
              loading: false,
              cacValidated: false,
              error: res.message,
              notify: false,
            });
          });
        }
        if (response.status >= 500) {
          this.setState({
            loading: false,
            cacValidated: false,
            error: "Unable to connect to Advancly services",
            notify: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          cacValidated: false,
          error: "Unable to connect to Advancly services",
          notify: false,
        });
      });
  };

  onBvnNumberInput = (e) => {
    this.setState({ error: false, bvnValidated: false, otp: "" });
    let bvnNumber = e.target.value;

    this.setState({ bvn: bvnNumber }, () => {
      if (bvnNumber.length === 11 && !this.state.bvnLoading) {
        this.setState({ bvnLoading: true, error: false });
        this.handleBvnValidation();
      }
      if (bvnNumber.length > 11 && !this.state.bvnLoading) {
        window.scrollTo(0, 0);
        this.setState({ error: `BVN is invalid`, notify: false });
        return;
      }
    });
  };

  handleBvnValidation = () => {
    const { borrowerType, bvn, email, state_token, aggregator } = this.state;

    this.setState({
      error: false,
      bvnLoading: true,
      otp: "",
      otpLoading: false,
      bvnValidated: false,
      duration: 2000 * 60,
      timerExpired: false,
    });

    // validate fields
    if (borrowerType === "") {
      this.setState({
        bvnLoading: false,
        error: `Please choose Borrower Type`,
        notify: false,
      });
      return;
    }
    if (email === "") {
      this.setState({
        bvnLoading: false,
        error: `Please enter your email address`,
        notify: false,
      });
      return;
    }
    if (bvn === "") {
      this.setState({
        bvnLoading: false,
        error: `Please enter BVN number`,
        notify: false,
      });
      return;
    }
    if (bvn.length !== 11) {
      this.setState({
        bvnLoading: false,
        error: "Please enter a valid BVN",
        notify: false,
      });
      return;
    }

    // POST
    if (borrowerType === "1") {
      // data to be sent to end
      let data = {
        borrower_type: borrowerType,
        bvn_number: bvn,
        aggregator_id: Number(aggregator),
        email: email,
        board_step: "first_step",
      };
      fetch(CONFIG.BASE_URL + "/account/direct_borrower_signup/", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            response.json().then((res) => {
              this.countDown();
              let bvnData = { bvn: bvn };
              let allowRedirect = { redirectToStep2: true };
              let borrower_type = { borrower_type: borrowerType };
              let email = { email: this.state.email };
              let aggregator = { aggregator_id: Number(this.state.aggregator) };
              Object.assign(res, bvnData);
              Object.assign(res, allowRedirect);
              Object.assign(res, borrower_type);
              Object.assign(res, email);
              Object.assign(res, aggregator);

              this.setState({
                bvnLoading: false,
                bvn_phone: `${res.phone}`,
                notify: `${res.message}`,
                state_token: `${res.state_token}`,
                bvnValidated: true,
                error: false,
              });
              this.props.upadateState(res);
            });
          }
          if (response.status === 400) {
            response.json().then((res) => {
              this.setState({
                bvnLoading: false,
                error: res.message,
                notify: false,
              });
            });
          }
          if (response.status >= 500) {
            this.setState({
              bvnLoading: false,
              error: "Unable to connect to Advancly services",
              notify: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            bvnLoading: false,
            error: "Unable to connect to Advancly services",
            notify: false,
          });
        });
    }

    if (borrowerType === "2") {
      // data to be sent to end
      let data = {
        borrower_type: borrowerType,
        bvn_number: bvn,
        aggregator_id: Number(aggregator),
        email: email,
        board_step: "second_step",
        state_token: state_token,
      };

      fetch(CONFIG.BASE_URL + "/account/direct_onboard_corporate_borrower/", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            response.json().then((res) => {
              this.countDown();
              let bvnData = { bvn: bvn };
              let allowRedirect = { redirectToStep2: true };
              let borrower_type = { borrower_type: borrowerType };
              let email = { email: this.state.email };
              let aggregator = { aggregator_id: Number(this.state.aggregator) };
              Object.assign(res, bvnData);
              Object.assign(res, allowRedirect);
              Object.assign(res, borrower_type);
              Object.assign(res, email);
              Object.assign(res, aggregator);

              this.setState({
                bvnLoading: false,
                bvn_phone: `${res.phone}`,
                notify: `${res.message}`,
                state_token: `${res.state_token}`,
                bvnValidated: true,
              });
              this.props.upadateState(res);
            });
          }
          if (response.status === 400) {
            response.json().then((res) => {
              this.setState({
                bvnLoading: false,
                error: res.message,
                notify: false,
              });
            });
          }
          if (response.status >= 500) {
            this.setState({
              bvnLoading: false,
              error: "Unable to connect to Advancly services",
              notify: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            bvnLoading: false,
            error: "Unable to connect to Advancly services",
            notify: false,
          });
        });
    }
  };

  onOtpInput = (e) => {
    this.setState({ error: false });
    let otpValue = e.target.value;

    this.setState({ otp: otpValue }, () => {
      if (otpValue.length === 5 && !this.state.otpLoading) {
        this.setState({ otpLoading: true, error: false });
        this.handleOtpValidation();
      }
      if (otpValue.length > 5 && !this.state.otpLoading) {
        window.scrollTo(0, 0);
        this.setState({ error: `OTP is invalid`, notify: false });
        return;
      }
    });
  };

  handleOtpValidation = () => {
    const { otp, state_token } = this.state;

    this.setState({ error: false, otpLoading: true, timerExpired: true });

    // data to be sent to endpoint
    let data = {
      state_token: state_token,
      otp: otp,
    };

    // POST
    fetch(CONFIG.BASE_URL + "/account/validate_otp/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          response.json().then((res) => {
            this.props.history.push("/create-account/details");
          });
        }

        if (response.status === 400) {
          response.json().then((res) => {
            this.setState({
              error: res.message,
              notify: false,
              otpLoading: false,
            });
            window.scrollTo(0, 0);
          });
        }

        if (response.status >= 500) {
          this.setState({
            error: "Unable to connect to Advancly services",
            notify: false,
            otpLoading: false,
          });
          window.scrollTo(0, 0);
        }
      })
      .catch(() => {
        this.setState({
          otpLoading: false,
          error: "Unable to connect to Advancly services",
          notify: false,
        });
      });
  };
  // fetch aggregator id by aggregator short name
  getAggregatorId = () => {
    fetch(
      CONFIG.BASE_URL +
        "/account/get_aggregator_id/?short_name=" +
        this.props.match.params.id,
      {
        method: "GET",
      }
    )
      .then((response) => {
        this.setState({ loading: false });

        if (response.status === 200 || response.status === 201) {
          response.json().then((res) => {
            this.setState({ aggregator: res.data });
          });
        }

        if (response.status === 400) {
          response.json().then((res) => {
            this.setState({ error: res.message, notify: false });
          });
        }

        if (response.status >= 500) {
          this.setState({
            error: "Unable to connect to Advancly services",
            notify: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: "Unable to connect to Advancly services",
          notify: false,
        });
      });
  };

  componentWillUnmount = () => {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  render() {
    const { aggregatorList } = this.props;
    const {
      error,
      notify,
      borrowerType,
      email,
      bvn,
      bvn_phone,
      cac_number,
      company_name,
      cacValidated,
      bvnValidated,
      emailValidated,
      otp,
      minutes,
      seconds,
      timerExpired,
      aggregator,
    } = this.state;

    const aggregatorOptions = aggregatorList.map((data, index) => {
      const { aggregator_id, biz_name } = data;
      return (
        <option value={aggregator_id} key={index}>
          {biz_name}
        </option>
      );
    });
    let the_minutes = "";
    if (minutes !== 0) {
      the_minutes = `${minutes} minute,`;
    }

    let the_seconds = "";
    if (seconds !== 0) {
      the_seconds = `${seconds - 1} second(s)`;
    }

    return (
      <div className="col-12 col-md-7 col-lg-5">
        <p className="h3 text-primary text-center">Create borrower account</p>
        <p className="mb-5 text-center">Enter your BVN for verification</p>

        {error && <p className="alert alert-warning small">{error}</p>}
        {notify && <p className="alert alert-warning small">{notify}</p>}

        <form className="form" onSubmit={this.handleCacValidation}>
          <div className="form-group">
            <label>Borrower type</label>
            <select
              className="form-control"
              name="borrowerType"
              onChange={this.handleChange}
            >
              <option value="1">Individual</option>
              <option value="2">Corporate</option>
            </select>
          </div>

          {borrowerType === "2" && (
            <div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Business Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_name"
                      value={company_name}
                      placeholder="ABC and co"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>CAC number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="cac_number"
                      value={cac_number}
                      placeholder="1234"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Aggregator</label>
                <select
                  name="aggregator"
                  className="form-control"
                  onChange={this.handleChange}
                  disabled={this.props.match.params.id}
                  value={aggregator}
                >
                  <option value="">select aggregator</option>
                  {aggregatorOptions}
                </select>
              </div>
            </div>
          )}

          {borrowerType === "2" && !cacValidated && (
            <button
              type="submit"
              value="submit"
              className="btn btn-sm btn-primary btn-block"
            >
              Submit
              {this.state.loading && (
                <span className="spinner-border spinner-border-sm ml-3"></span>
              )}
            </button>
          )}

          {borrowerType === "2" && cacValidated && (
            <div>
              <div className="form-group">
                <label>Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={email}
                  placeholder="johndoe@advancly.com"
                  onChange={this.handleEmailChange}
                />
              </div>

              <fieldset disabled={!emailValidated}>
                <label>BVN</label>
                {this.state.bvnLoading && (
                  <span className="spinner-border spinner-border-sm ml-2"></span>
                )}

                {borrowerType === "2" && (
                  <p className="mb-0">Enter your primary Director's BVN</p>
                )}
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    name="bvn"
                    value={bvn}
                    placeholder="1234567890"
                    onChange={this.onBvnNumberInput}
                  />
                </div>
              </fieldset>

              {/* if BVN is valid */}
              {bvnValidated && (
                <div>
                  <label>OTP</label>
                  {this.state.otpLoading && (
                    <span className="spinner-border spinner-border-sm ml-2"></span>
                  )}

                  <p className="mb-0">Enter the OTP sent to {email}</p>
                  {!timerExpired ? (
                    <p className="mb-0">
                      This OTP code expires in {the_minutes} {the_seconds}
                    </p>
                  ) : (
                    <Link to="#" onClick={this.handleBvnValidation}>
                      resend code
                    </Link>
                  )}
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      name="otp"
                      value={otp}
                      placeholder="123"
                      onChange={this.onOtpInput}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {borrowerType === "1" && (
            <div>
              <div className="form-group">
                <label>Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={email}
                  placeholder="johndoe@advancly.com"
                  onChange={this.handleEmailChange}
                />
              </div>

              <div className="form-group">
                <label>Aggregator</label>
                <select
                  name="aggregator"
                  className="form-control"
                  onChange={this.handleChange}
                  disabled={this.props.match.params.id}
                  value={aggregator}
                >
                  <option value="">select aggregator</option>
                  {aggregatorOptions}
                </select>
              </div>

              <fieldset disabled={!emailValidated}>
                <label>BVN</label>
                {borrowerType === "2" && (
                  <p className="mb-0">Enter your primary Director's BVN</p>
                )}
                {this.state.bvnLoading && (
                  <span className="spinner-border spinner-border-sm ml-2"></span>
                )}
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    name="bvn"
                    value={bvn}
                    placeholder="1234567890"
                    onChange={this.onBvnNumberInput}
                  />
                </div>
              </fieldset>

              {/* if BVN is valid */}
              {bvnValidated && (
                <div>
                  <label>OTP</label>
                  {this.state.otpLoading && (
                    <span className="spinner-border spinner-border-sm ml-2"></span>
                  )}

                  <p className="mb-0">
                    Enter the OTP sent to {bvn_phone} and {email}
                  </p>
                  {bvnValidated && !timerExpired ? (
                    <p className="mb-0">
                      This OTP code expires in {the_minutes} {the_seconds}
                    </p>
                  ) : (
                    <Link
                      to="#"
                      role="button"
                      onClick={this.handleBvnValidation}
                    >
                      resend code
                    </Link>
                  )}
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      name="otp"
                      value={otp}
                      placeholder="123"
                      onChange={this.onOtpInput}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </form>
      </div>
    );
  }
}

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userImage: "",
      loading: false,
      accountLoading: false,
      error: false,
      passwordError: false,
      notify: false,
      accountNumber: "",
      selectedBank: "",
      accountName: "",
      bvn: "",
      password: "",
      borrower_phone: "",
      accountValidated: false,
      agg_short_name: localStorage.getItem("agg_short_name"),
      bankList: [
        {
          code: "0",
          id: 1,
          longcode: "0",
          name: "Please check your network connection",
          slug: "error",
        },
      ],
    };
  }

  componentDidMount() {
    if (
      !this.props.res.redirectToStep2 &&
      this.state.agg_short_name &&
      this.state.agg_short_name !== "details"
    ) {
      this.props.history.replace(
        "/create-account/" + this.state.agg_short_name
      );
    } else if (!this.props.res.redirectToStep2) {
      this.props.history.replace("/create-account");
    }
  }

  timeOutAction = () => {
    this.props.history.push("/login");
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleImageUpload = (e) => {
    let image = e.target.files[0];
    let reader = new FileReader();
    let that = this;

    reader.addEventListener("load", (evt) => {
      that.setState({ userImage: evt.target.result });
    });

    reader.readAsDataURL(image);
  };

  onAccountNumberInput = (e) => {
    this.setState({ error: false, accountValidated: false, accountName: "" });
    let number = e.target.value;

    if (this.state.selectedBank === "") {
      this.setState({ error: `Please select a bank` });
      return;
    }

    this.setState({ accountNumber: number }, () => {
      if (number.length === 10 && !this.state.accountLoading) {
        this.setState({ accountLoading: true });
        //validate account number and bank name
        let data = {
          bank_code: this.state.selectedBank,
          bank_account_num: number,
        };
        this.validateAccount(data);
      }
    });
  };

  onBankSelect = (e) => {
    this.setState({ error: false, accountValidated: false });
    let selected = e.target.value;

    this.setState({ selectedBank: selected }, () => {
      if (
        this.state.accountNumber.length === 10 &&
        !this.state.accountLoading
      ) {
        this.setState({ accountLoading: true });

        let data = {
          bank_code: selected,
          bank_account_num: this.state.accountNumber,
        };

        this.validateAccount(data);
      }
    });
  };

  validateAccount = (data) => {
    fetch(CONFIG.BASE_URL + "/account/validate_bank_account/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.setState({ loading: false, accountName: "" });

        if (response.status === 200 || response.status === 201) {
          response.json().then((res) => {
            this.setState({
              accountName: res.bank_account_name,
              accountLoading: false,
              accountValidated: true,
            });
          });
        }

        if (response.status === 400) {
          response.json().then((res) => {
            window.scrollTo(0, 0);
            this.setState({
              error: res.message,
              accountValidated: false,
              accountLoading: false,
            });
          });
        }

        if (response.status >= 500) {
          window.scrollTo(0, 0);
          this.setState({
            error: "Unable to connect to Advancly services.",
            notify: false,
            accountLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: "Unable to connect to Advancly services",
          notify: false,
          accountName: "",
          accountLoading: false,
        });
      });
  };

  handlePassword = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "password") {
      this.setState({ [name]: value });
    }

    if (
      (name === "confirm_password" && value !== this.state.password) ||
      (name === "password" && value !== this.state.confirm_password)
    ) {
      this.setState({ passwordError: "Passwords do not match" });
    } else {
      this.setState({ passwordError: false });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    e.persist();

    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true, error: false });

    // if (this.state.borrower_phone.length < 11) {
    //   this.setState({
    //     loading: false,
    //     error: "Please enter a valid 11 digit phone number",
    //     notify: false,
    //   });
    //   window.scrollTo(0, 0);
    // }

    let elements = e.target.elements;
    let city = e.target.elements["city"].value;
    let state = e.target.elements["state"].value;
    let gender = e.target.elements["gender"].value;
    let bank_code = e.target.elements["bank_code"].value;
    let borrower_phone = e.target.elements["borrower_phone"].value;
    let residence_address = e.target.elements["residence_address"].value;
    let password = e.target.elements["password"].value;
    let photo_url = e.target.elements["photo"].value;
    let isValid = true;

    for (var i = 0, len = elements.length; i < len; i++) {
      if (elements[i].value === "") {
        isValid = false;
        window.scrollTo(0, 0);

        if (elements[i].name === "photo") {
          this.setState({
            loading: false,
            error: "Please choose a photo",
            notify: false,
          });
          break;
        }

        if (elements[i].name === "bank_code") {
          this.setState({
            loading: false,
            error: "Please enter your business RC number",
            notify: false,
          });
          break;
        }

        if (elements[i].name === "bank_account_num") {
          this.setState({
            loading: false,
            error: "Please enter your account number",
            notify: false,
          });
          break;
        }

        if (elements[i].name === "borrower_phone") {
          this.setState({
            loading: false,
            error: "Please enter phone number",
            notify: false,
          });
          break;
        }

        if (elements[i].name === "residence_address") {
          this.setState({
            loading: false,
            error: "Please enter address",
            notify: false,
          });
          break;
        }

        if (elements[i].name === "confirm_password") {
          this.setState({
            loading: false,
            error: "Please confirm your password",
            notify: false,
          });
          break;
        }

        if (elements[i].name === "confirm_password") {
          this.setState({
            loading: false,
            error: "Please confirm your password",
            notify: false,
          });
          break;
        }

        this.setState({
          loading: false,
          error: `Please enter ${elements[i].name}`,
        });
        break;
      } else {
        continue;
      }
    }

    if (isValid) {
      const { state_token, aggregator_id, first_name, last_name, email } =
        this.props.res;
      const { accountName, accountNumber } = this.state;

      // send borrower's personal details
      if (this.props.res.borrower_type === "1") {
        const data = {
          state_token,
          aggregator_id: Number(aggregator_id),
          first_name,
          last_name,
          email,
          city,
          state,
          gender,
          password,
          photo_url,
          borrower_phone,
          residence_address,
          bank_account_num: accountNumber,
          bank_code: bank_code,
          bank_account_name: accountName,
          is_widget: false,
          board_step: "second_step",
        };

        fetch(CONFIG.BASE_URL + "/account/direct_borrower_signup/", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            this.setState({ loading: false });
            window.scrollTo(0, 0);

            if (response.status === 200 || response.status === 201) {
              e.target.reset();
              window.scrollTo(0, 0);
              response.json().then((res) => {
                this.setState({ notify: `${res.message}` });
              });

              localStorage.removeItem("agg_short_name");
              setTimeout(this.timeOutAction, 7000);
            }

            if (response.status === 400) {
              response.json().then((res) => {
                this.setState({ error: res.message });
              });
            }

            if (response.status >= 500) {
              this.setState({
                error: "Unable to connect to Advancly services",
                notify: false,
              });
            }
          })
          .catch(() => {
            this.setState({
              loading: false,
              error: "Unable to connect to Advancly services",
              notify: false,
            });
            window.scrollTo(0, 0);
          });
      }

      // send corporate details
      if (this.props.res.borrower_type === "2") {
        debugger;
        const newData = {
          state_token,
          aggregator_id: Number(aggregator_id),
          first_name,
          last_name,
          email,
          city,
          state,
          gender,
          password,
          bank_account_num: accountNumber,
          bank_code: bank_code,
          bank_account_name: accountName,
          borrower_phone,
          residence_address,
          board_step: "third_step",
        };
        fetch(CONFIG.BASE_URL + "/account/direct_onboard_corporate_borrower/", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(newData),
        })
          .then((response) => {
            this.setState({ loading: false });
            window.scrollTo(0, 0);

            if (response.status === 200 || response.status === 201) {
              e.target.reset();
              window.scrollTo(0, 0);
              response.json().then((res) => {
                // this.setState({ error: `${res.message} ` });
                this.setState({ notify: "Your onboarding was successful" });
              });

              setTimeout(this.timeOutAction, 15000);
            }

            if (response.status === 400) {
              response.json().then((res) => {
                this.setState({ error: res.message, notify: false });
              });
            }

            if (response.status >= 500) {
              this.setState({
                error: "Unable to connect to Advancly services",
                notify: false,
              });
            }
          })
          .catch(() => {
            this.setState({
              loading: false,
              error: "Unable to connect to Advancly services",
              notify: false,
            });
            window.scrollTo(0, 0);
          });
      }
    }
  };

  render() {
    let avatarStyle = {
      background: `#77838f url(${this.state.userImage})`,
    };
    const { bankList, bvn, first_name, last_name, email } = this.props.res;

    const bankOptionsList = bankList.map((data, index) => {
      return (
        <option value={data.code} key={index}>
          {data.name}
        </option>
      );
    });

    return (
      <div className="col-12 col-md-7 col-lg-7">
        <p className="h3 text-primary text-center">Create borrower account</p>
        <p className="mb-5 text-center">
          BVN validated successfully, Enter your personal details
        </p>
        {this.state.error && (
          <p className="alert alert-danger small mb-4">{this.state.error}</p>
        )}
        {this.state.notify && (
          <p className="alert alert-success small mb-4">{this.state.notify}</p>
        )}
        <form className="form" onSubmit={this.handleSubmit}>
          <div className="d-flex flex-row justify-content-center mb-3">
            <div className="form-avatar" style={avatarStyle}></div>
          </div>
          <label
            className="btn btn-sm btn-secondary btn-block file-attachment-btn mb-4"
            htmlFor="avatar"
          >
            Upload your photo
            <input
              onChange={this.handleImageUpload}
              id="avatar"
              name="photo"
              type="file"
              className="file-attachment-btn__label"
              accept="image/*"
            />
          </label>

          <div className="form-group">
            <label>Bank name</label>
            <select
              className="form-control"
              name="bank_code"
              onInput={this.onBankSelect}
            >
              <option value="">select bank</option>
              {bankOptionsList}
            </select>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Account number</label>
                <input
                  type="number"
                  className="form-control"
                  name="bank_account_num"
                  onChange={this.onAccountNumberInput}
                  placeholder="0123456789"
                  value={this.state.accountNumber}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Account name</label>
                {this.state.accountLoading && (
                  <span className="spinner-border spinner-border-sm ml-2"></span>
                )}
                <input
                  type="text"
                  name="bank_account_name"
                  className="form-control"
                  defaultValue={this.state.accountName}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>BVN</label>
                <input
                  type="number"
                  className="form-control"
                  name="bvn_number"
                  value={bvn}
                  disabled
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Phone number</label>
                <input
                  type="number"
                  className="form-control"
                  name="borrower_phone"
                  placeholder="12345678901"
                  value={this.state.borrower_phone}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>First name</label>
                <input
                  type="text"
                  className="form-control"
                  name="first name"
                  value={first_name}
                  disabled
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Last name</label>
                <input
                  type="text"
                  className="form-control"
                  name="last name"
                  value={last_name}
                  disabled
                />
              </div>
            </div>
          </div>

          {this.state.accountValidated && (
            <div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label>Gender</label>
                    <select className="form-control" name="gender">
                      <option value=""></option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Address</label>
                <textarea
                  name="residence_address"
                  className="form-control"
                  style={{ resize: "none" }}
                  placeholder="11 Neverland Drive"
                ></textarea>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      placeholder="Neverland"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>State</label>
                    <select className="form-control" name="state">
                      <option value="fct">Abuja (FCT)</option>
                      <option value="abia">Abia</option>
                      <option value="adamawa">Adamawa</option>
                      <option value="akwa ibom">Akwa Ibom</option>
                      <option value="anambra">Anambra</option>
                      <option value="bauchi">Bauchi</option>
                      <option value="bayelsa">Bayelsa</option>
                      <option value="benue">Benue</option>
                      <option value="borno">Borno</option>
                      <option value="cross river">Cross River</option>
                      <option value="delta">Delta</option>
                      <option value="ebonyi">Ebonyi</option>
                      <option value="edo">Edo</option>
                      <option value="ekiti">Ekiti</option>
                      <option value="enugu">Enugu</option>
                      <option value="gombe">Gombe</option>
                      <option value="imo">Imo</option>
                      <option value="jigawa">Jigawa</option>
                      <option value="kaduna">Kaduna</option>
                      <option value="kano">Kano</option>
                      <option value="katsina">Katsina</option>
                      <option value="kebbi">Kebbi</option>
                      <option value="kogi">Kogi</option>
                      <option value="kwara">Kwara</option>
                      <option value="lagos">Lagos</option>
                      <option value="nasarawa">Nasarawa</option>
                      <option value="niger">Niger</option>
                      <option value="ogun">Ogun</option>
                      <option value="ondo">Ondo</option>
                      <option value="osun">Osun</option>
                      <option value="oyo">Oyo</option>
                      <option value="plateau">Plateau</option>
                      <option value="rivers">Rivers</option>
                      <option value="sokoto">Sokoto</option>
                      <option value="taraba">Taraba</option>
                      <option value="yobe">Yobe</option>
                      <option value="zamfara">Zamfara</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="password"
                      onInput={this.handlePassword}
                      hasActionButton={true}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    {this.state.passwordError ? (
                      <label className="alert-danger password_error pl-2 pr-2">
                        {this.state.passwordError}
                      </label>
                    ) : (
                      <label>Confirm Password</label>
                    )}
                    <input
                      type="password"
                      name="confirm_password"
                      className="form-control"
                      placeholder="password"
                      onInput={this.handlePassword}
                      hasActionButton={true}
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                value="submit"
                className="btn btn-sm btn-primary btn-block"
              >
                Create account
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm ml-3"></span>
                )}
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const Step1WithRouter = withRouter(Step1);
const Step2WithRouter = withRouter(Step2);

export default CreateAccount;
