import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack, MdArrowForwardIos } from "react-icons/all";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import logo from "../../../../../../assets/loans/loanHistory.svg";
import { formatMoney } from "../../../../../../helpers/formatCurrency";
import LoanHistoryDetails from "./LoanHistoryDetails";
import moment from "moment";
import Loader from "../../../../../Loader/Loader";
import { getDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import { UserProp } from "../../../Dashboard/Dashboard.component";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import { CurrencyProp } from "../../../types";

export type LoanProp = {
  loan_id?: number;
  loan_status?: string;
  loan_effective_date?: string;
  has_disburse?: boolean;
  has_repaid?: boolean;
  loan_amount?: number;
  loan_amount_repay?: number;
  loan_due_date?: string;
  loan_interest_amount?: number;
  loan_outstanding?: number;
  loan_repayment_balance?: number;
  loan_ref?: string;
  loan_tenure?: number;
  modified_date?: string;
  pub_date?: string;
  status?: string;
  borrower_id?: number;
  encryptedKey?: string;
  aggregator_id?: number;
  onCancel?: () => void;
  currency?: CurrencyProp;
};

const LoanHistory = ({ wallet, currency }: UserProp) => {
  let location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [loanHistory, setLoanHistory] = useState<LoanProp[]>([]);
  const [showDetails, setSetShowDetails] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState<LoanProp | null>(null);

  useEffect(() => {
    appInsights.trackPageView({ name: "LoanHistory.tsx", isLoggedIn: true });
  }, []);

  async function fetchLoanHistory() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getBorrowerLoansInWidget}?bvn=${
          wallet.bvn_number
        }&aggregator_domain=${window.location.origin}&aggregator_id=${Number(
          wallet.aggregator_id
        )}`,
        wallet.encryptedKey
      );
      setLoanHistory(response.data);
    } catch (error: any) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanHistory },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchLoanHistory();
  }, []);

  if (showDetails) {
    return (
      <LoanHistoryDetails
        loan_id={selectedLoan?.loan_id}
        loan_status={selectedLoan?.loan_status}
        loan_effective_date={selectedLoan?.loan_effective_date}
        has_disburse={selectedLoan?.has_disburse}
        has_repaid={selectedLoan?.has_repaid}
        loan_amount={selectedLoan?.loan_amount}
        loan_amount_repay={selectedLoan?.loan_amount_repay}
        loan_due_date={selectedLoan?.loan_due_date}
        loan_interest_amount={selectedLoan?.loan_interest_amount}
        loan_outstanding={selectedLoan?.loan_outstanding}
        loan_repayment_balance={selectedLoan?.loan_repayment_balance}
        loan_ref={selectedLoan?.loan_ref}
        loan_tenure={selectedLoan?.loan_tenure}
        modified_date={selectedLoan?.modified_date}
        pub_date={selectedLoan?.pub_date}
        status={selectedLoan?.status}
        borrower_id={wallet.customer_id}
        encryptedKey={wallet.encryptedKey}
        aggregator_id={wallet.aggregator_id}
        onCancel={() => setSetShowDetails(false)}
        currency={selectedLoan?.currency || ""}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div onClick={() => history.push(ROUTES.widgetLoan)} className="cursor">
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Loan History</h4>
      <br />
      {loanHistory.length === 0 ? (
        <h4 className="text-center">No Data Available</h4>
      ) : (
        loanHistory?.map((item) => (
          <div key={item.loan_id}>
            <div
              className="card cursor"
              onClick={() => {
                setSelectedLoan(item);
                setSetShowDetails(true);
              }}
            >
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className=" mr-3">
                    <img
                      src={logo}
                      alt=""
                      className="img-fluid"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                  <div>
                    <p className="font-weight-bold text-dark padding-none">
                      {formatMoney(item?.loan_amount || 0, item.currency || "")}
                      <small
                        className={`ml-2 ${
                          item?.loan_status?.toLowerCase() === "active"
                            ? " text-warning"
                            : "text-success"
                        }`}
                      >
                        {item?.loan_status}
                      </small>
                    </p>
                    {item?.loan_effective_date && (
                      <small className="text-muted padding-none">
                        {moment(item?.loan_effective_date).format(
                          "D/MM/YYYY, h:mm a"
                        )}
                      </small>
                    )}
                  </div>
                </div>

                <MdArrowForwardIos />
              </div>
            </div>
            <br />
          </div>
        ))
      )}

      {currency === "NGN" ? (
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={() => history.push(ROUTES.widgetProfile)}
            >
              Update card details
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LoanHistory;
