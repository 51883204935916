import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { appInsights } from "../../../../../AppInsight/AppInsight";

const LoanRepaymentViaNewCard = () => {
  let location = useLocation();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRepaymentViaNewCard.tsx",
      isLoggedIn: true,
    });
  }, []);
  return <div>LoanRepaymentViaNewCard</div>;
};

export default LoanRepaymentViaNewCard;
