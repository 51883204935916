import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Checkbox } from "antd";
import { StepProp } from "./LoanRequest";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import { SuccessComponent } from "../../../SuccessComponent";
import Loader from "../../../../../Loader/Loader";
import { appInsights } from "../../../../../AppInsight/AppInsight";

const LoanRequestStepFour = ({
  createLoanData,
  encryptedKey,
  handleGoBack,
}: StepProp) => {
  let location = useLocation();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRequestStepFour.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function handleCreateLoan() {
    setIsLoading(true);
    try {
      await postDataWithKey(
        apiEndpoints.processLoan,
        createLoanData,
        encryptedKey
      );
      setShowSuccess(true);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRequestStepFour },
      });
    }
    setIsLoading(false);
  }

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Request Successful!"
        body="Your request has been received and will be reviewed for approval"
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <h4>Terms and agreement</h4>
      <br />
      <div
        className="card"
        style={{ backgroundColor: "#F4F5F6", borderRadius: "10px" }}
      >
        <div className="card-body">
          <p>Dear customer, thank you for choosing Advancly.</p>
          <p>
            Please read the terms and conditions below carefully and tick the
            box below to agree to the terms of this loan application.
          </p>
          <p>If you pay late…. </p>
          <ul>
            <li className="text-muted">
              Late repayment may attract additional charges.
            </li>
            <li className="text-muted">
              Advancly may notify the credit bureau of your default, and this
              will result in you being unable to access credit easily across the
              entire financial industry.
            </li>
          </ul>
          <p>
            Please note that your card details will be required for repayment
            and only be charged for your loan repayment.
          </p>

          <Checkbox
            className="text-muted"
            onChange={(e) => {
              setAcceptTerms(e.target.checked);
            }}
          >
            I agree to these terms and condition.
          </Checkbox>
        </div>
      </div>

      <div className="d-flex justify-content-end" style={{ marginTop: "10%" }}>
        <div className="d-flex align-items-center">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={handleGoBack}
          >
            Cancel my request
          </p>
          <button
            className="btn btn-primary btn-radius"
            disabled={!acceptTerms}
            onClick={handleCreateLoan}
          >
            I understand
          </button>
        </div>
      </div>
    </>
  );
};

export default LoanRequestStepFour;
