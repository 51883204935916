import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Input, InputNumber, Select } from "antd";
import { LoanProduct, StepProp } from "./LoanRequest";
import { formatCurrency } from "../../../../../../helpers/formatCurrency";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import Loader from "../../../../../Loader/Loader";
import { appInsights } from "../../../../../AppInsight/AppInsight";

const LoanRequestStepTwo = ({
  handleNext,
  handleGoBack,
  loanProducts,
  loanData,
  setLoanData,
  encryptedKey,
  setProcessedLoanData,
  setCreateLoanData,
  createLoanData,
}: StepProp) => {
  let location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [selectedLoanProduct, setSelectedLoanProduct] =
    useState<LoanProduct | null>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSetSelectedLoan = (id: number) => {
    const response = loanProducts?.find((l) => l.id === id);
    form.setFieldsValue({ principalAmount: "", loanTenor: "" });
    setSelectedLoanProduct(response);
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRequestStepTwo.tsx",
      isLoggedIn: true,
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const onSubmit = async (values: {
    productId: number;
    loanTenor: string;
    principalAmount: string;
  }) => {
    console.log({ values });
    let principalAmount = Number(
      values.principalAmount!.replace(/\$\s?|(,*)/g, "").trim()
    );
    if (setLoanData) {
      setLoanData({
        ...loanData,
        productId: Number(values.productId),
        loanTenor: Number(values.loanTenor),
        principalAmount,
        loan_name: selectedLoanProduct?.product_name,
      });
      setIsLoading(true);
      try {
        loanData.productId = Number(values.productId);
        loanData.loanTenor = Number(values.loanTenor);
        loanData.principalAmount = principalAmount;
        loanData.loan_name = selectedLoanProduct?.product_name;
        const response = await postDataWithKey(
          apiEndpoints.generateLoanDetails,
          {
            productId: Number(values.productId),
            loanTenor: Number(values.loanTenor),
            principalAmount: principalAmount,
            loan_name: selectedLoanProduct?.product_name,
            loanEffectiveDate: new Date(),
            aggregatorId: loanData.aggregator_id,
          },
          encryptedKey
        );
        response.data.product_name = loanData.loan_name;
        response.data.loan_amount = principalAmount;
        if (setProcessedLoanData) {
          console.log({ response: response.data });
          setProcessedLoanData(response.data);
        }
        if (createLoanData && setCreateLoanData) {
          setCreateLoanData({
            ...createLoanData,
            loan_amount: response.data.totalPrincipalDisbursed,
            loan_name: selectedLoanProduct?.product_name,
            product_id: Number(values.productId),
            loan_tenure: Number(values.loanTenor),
          });
          handleNext();
        }
      } catch (error) {
        errorToast(errorHandler(error));
        appInsights.trackException({
          exception: error,
          properties: { fileName: LoanRequestStepTwo },
        });
      }
      setIsLoading(false);
    } else {
      errorToast("Something went wrong, please try again");
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <p className="text-muted">Enter your loan information</p>
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => onSubmit(values)}
      >
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue=""
          name="productId"
          label="Loan product"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Select
            placeholder="Select"
            allowClear
            onChange={(val) => {
              handleSetSelectedLoan(val);
            }}
          >
            {loanProducts &&
              loanProducts?.length > 0 &&
              loanProducts.map((item) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.product_name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue=""
          name="principalAmount"
          label="Loan amount"
          rules={[
            { required: true, message: "Required field" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  (selectedLoanProduct?.maximum_amount &&
                    selectedLoanProduct?.maximum_amount >=
                      Number(value!.replace(/\$\s?|(,*)/g, "")))
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    `Maximum loan amount for the specific loan product is ${formatCurrency(
                      selectedLoanProduct?.maximum_amount
                    )}`
                  )
                );
              },
            }),
          ]}
        >
          <div>
            <InputNumber
              size="large"
              formatter={(value) =>
                ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </div>
        </Form.Item>

        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue=""
          name="loanTenor"
          label="Loan Tenure (days)"
          rules={[
            { required: true, message: "Required field" },
            {
              pattern: /^\d*[.]?\d*$/,
              message: "Enter numbers from 0 - 9",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  (selectedLoanProduct?.maximum_tenor &&
                    selectedLoanProduct?.maximum_tenor * 30 >= Number(value))
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    `Maximum loan tenure for this product is ${
                      selectedLoanProduct?.maximum_tenor &&
                      selectedLoanProduct?.maximum_tenor * 30
                    }days`
                  )
                );
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "20%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={handleGoBack}
            >
              Go Back
            </p>
            <button className="btn btn-primary btn-radius">Next</button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default LoanRequestStepTwo;
