import React from "react";
import styles from "./WidgetInitializationError.module.scss";

export default function WidgetInitializationError() {
  return (
    <div className={styles.widgetErrorContainer}>
      <h3>
        An error occured while initializing the widget. Please refresh this page
        and try again.
      </h3>
      <button
        className="btn btn-sm mt-5"
        onClick={() => window.location.reload()}
      >
        Refresh
      </button>
    </div>
  );
}
