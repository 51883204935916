import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  BiRightArrowAlt,
  BsCreditCardFill,
  IoMdArrowRoundBack,
  IoWalletOutline,
  RiFileCopyFill,
} from "react-icons/all";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { formatCurrency } from "../../../../../../helpers/formatCurrency";
import {
  getDataWithKey,
  postDataWithKey,
} from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import NewLoader from "../../../components/NewLoader";
import { UserProp, WalletProp } from "../../../Dashboard/Dashboard.component";
import { SuccessComponent } from "../../../SuccessComponent";
import styles from "../../../../WidgetFlow.module.scss";
import { deviceType } from "react-device-detect";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import AppCard from "../../../components/AppCard";

const LoanRepaymentMethods = ({ wallet, currency }: UserProp) => {
  let location = useLocation<any>();
  const history = useHistory();
  const paystackRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [walletDetails, setWalletDetails] = useState<WalletProp | null>();
  const [kenyaBank, setKenyaBank] = useState({});

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRepaymentMethods.tsx",
      isLoggedIn: true,
    });
  }, []);

  async function handleRepaymentWithCard() {
    setIsLoading(true);
    try {
      await postDataWithKey(
        apiEndpoints.makeRepayment,
        {
          aggregator_id: wallet.aggregator_id,
          borrower_id: wallet.customer_id,
          borrower_repayment_mode: "Card",
          loan_id: location?.state?.loan_id,
          amount: Number(location?.state?.amount),
        },
        wallet.encryptedKey
      );
      setDone(true);
    } catch (error: any) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepaymentMethods },
      });
    }
    setIsLoading(false);
  }
  async function fetchWallet() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${wallet.customer_id}&aggregator_id=${wallet.aggregator_id}`,
        wallet.encryptedKey
      );
      setWalletDetails(response.data);
    } catch (error: any) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepaymentMethods },
      });
    } finally {
      setIsLoading(false);
    }
  }

  const fetchCollectionAccounts = async () => {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getCollectionAccounts}?AggregatorId=${
          wallet.aggregator_id
        }&Currency=${"KES"}&Type=1`,
        wallet.encryptedKey
      );
      console.log({ collectionAcct: response });
      setWalletDetails(response.data[0]);
    } catch (error: any) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepaymentMethods },
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (wallet.customer_id && wallet.aggregator_id) {
      if (currency !== "NGN") {
        fetchCollectionAccounts();
      } else {
        fetchWallet();
      }
    }
  }, [wallet]);

  if (isLoading) {
    return <NewLoader />;
  }

  const nigeriaPayment = () => {
    return (
      <>
        <div
          className="card cursor"
          style={{ backgroundColor: "#713FFF", height: "108px" }}
          onClick={() =>
            history.push({
              pathname: ROUTES.widgetLoanRepaymentViaWallet,
              state: {
                loan_id: location?.state?.loan_id,
                amount: location?.state?.amount,
              },
            })
          }
        >
          <div className="card-body d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div
                className={`${
                  deviceType === "mobile bg-primary-dark"
                    ? styles.iconMobile
                    : styles.icon
                } icon__primary mr-3`}
                style={{
                  borderRadius: "10px",
                }}
              >
                <div>
                  <IoWalletOutline
                    className="text-white text-center"
                    size="20px"
                  />
                </div>
              </div>
              <div>
                <p className="font-weight-bold text-white padding-none">
                  Pay with wallet
                </p>
                <small className="text-white padding-none">
                  <span>&#8358;</span>{" "}
                  {formatCurrency(walletDetails?.availableBalance || 0)}
                </small>
              </div>
            </div>

            <BiRightArrowAlt className="text-white" size="20px" />
          </div>
        </div>
        <br />

        <div
          className="card cursor"
          style={{ height: "108px" }}
          onClick={() => handleRepaymentWithCard()}
        >
          <div className="card-body d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div
                className="icon icon__primary mr-3"
                style={{ backgroundColor: "#9DA9B3", borderRadius: "10px" }}
              >
                <div style={{ marginTop: "-2px" }}>
                  <BsCreditCardFill className="text-white" size="20px" />
                </div>
              </div>
              <div>
                <p className="font-weight-bold text-primary padding-none">
                  Pay with existing card
                </p>
                {/*<small className=" padding-none">******6789</small>*/}
              </div>
            </div>

            <BiRightArrowAlt className="" size="20px" />
          </div>
        </div>
      </>
    );
  };

  const kenyaPayment = () => {
    return (
      <>
        <div className="title">Bank Transfer</div>
        <p>
          Kindly transfer the amount above to this unique account number using
          your mobile banking or ussd.
        </p>
        <AppCard
          className="d-flex justify-content-between"
          handleCopy={() => null}
        >
          <div
            className="d-flex align-items-center justify-content-between w-100"
            onClick={() => null}
          >
            <div>
              <p className="mr-1 padding-none" style={{ color: "#8B8699" }}>
                {walletDetails?.accountName}
              </p>
              <p className="title padding-none" id="accountNumber">
                {walletDetails?.accountNumber}
              </p>
              <p className="mr-1 padding-none" style={{ color: "#8B8699" }}>
                {walletDetails?.bankName}
              </p>
            </div>

            <div className="d-flex align-self-end align-items-center cursor-pointer">
              <RiFileCopyFill className="text-primary" />
              <p
                id="accountNumber"
                className="color-mid-purple ml-2 padding-none"
              >
                Copy
              </p>
            </div>
          </div>
        </AppCard>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={() => history.goBack()}
            >
              Go Back
            </p>
            <button
              className="btn btn-primary btn-radius"
              onClick={() =>
                history.push({
                  pathname: ROUTES.widgetLoanRepaymentProof,
                  state: {
                    loan_id: location?.state?.loan_id,
                    amount: location?.state?.amount,
                  },
                })
              }
              // disabled={!walletDetails?.accountNumber}
            >
              I have transferred
            </button>
          </div>
        </div>
      </>
    );
  };

  if (done) {
    return (
      <SuccessComponent
        btnText="Done"
        body="Your loan repayment is being processed"
        title="Repayment Successful!"
        handler={() => history.push(ROUTES.widgetLoan)}
        type="success"
      />
    );
  }

  if (currency !== "NGN") {
    return kenyaPayment();
  } else {
    return nigeriaPayment();
  }
};

export default LoanRepaymentMethods;
