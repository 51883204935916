import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  formatCurrency,
  formatMoney,
} from "../../../../../../helpers/formatCurrency";
import { StepProp } from "./LoanRequest";
import { appInsights } from "../../../../../AppInsight/AppInsight";

const LoanRequestStepOne = ({
  handleNext,
  handleGoBack,
  walletDetails,
  currency,
}: StepProp) => {
  let location = useLocation();
  const history = useHistory();
  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRequestStepOne.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <>
      <p className="text-muted">
        Funds will be disbursed into this wallet when your loan is approved
      </p>
      <br />
      <div className="d-flex justify-content-center">
        <div className="walletCard">
          <div>
            <div className="d-flex align-items-center">
              <p className="text-white mr-2">Wallet Balance</p>
            </div>

            <div>
              <h3 className="text-white text-center">
                {formatMoney(
                  walletDetails?.availableBalance || 0,
                  currency || ""
                )}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end" style={{ marginTop: "20%" }}>
        <div className="d-flex align-items-center">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={handleGoBack}
          >
            Go Back
          </p>
          <button className="btn btn-primary btn-radius" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default LoanRequestStepOne;
