import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/all";
import { Form, Input } from "antd";
import { ROUTES } from "../../../../../helpers/ROUTES";
import { appInsights } from "../../../../AppInsight/AppInsight";

type ProfileDetailProp = {
  first_name: string;
  last_name: string;
  borrower_phone: string;
  email: string;
  customer_type: string;
  company_name: string;
  bvn: string;
  business_registration_number: string;
};

const ProfileDetails = ({
  first_name,
  last_name,
  borrower_phone,
  email,
  customer_type,
  company_name,
  business_registration_number,
  bvn,
}: ProfileDetailProp) => {
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    appInsights.trackPageView({ name: "ProfileDetails.tsx", isLoggedIn: true });
  }, []);
  return (
    <div>
      <div
        onClick={() => history.push(ROUTES.widgetProfile)}
        className="cursor"
      >
        <IoMdArrowRoundBack size="26px" />
      </div>
      <br />
      <h4>Profile Information</h4>
      <Form layout="vertical" form={form}>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={first_name}
          name="firstName"
          label="First Name"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={last_name}
          name="lastName"
          label="Last Name"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={email}
          name="email"
          label="Email"
          required
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={borrower_phone}
          name="phone"
          label="Phone Number"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={bvn}
          name="bvn"
          label="Identity Number"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue={customer_type === "1" ? "Individual" : "Corporate"}
          name="customer_type"
          label="Customer Type"
        >
          <Input readOnly />
        </Form.Item>
        {customer_type !== "1" && (
          <>
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              initialValue={company_name}
              name="company_name"
              label="Company Name"
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              initialValue={business_registration_number}
              name="business_registration_number"
              label="Business Registration Number"
            >
              <Input readOnly />
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );
};

export default ProfileDetails;
