import React, { Component, Fragment } from "react";
import styles from "./WidgetFlow.module.scss";
import { Route, withRouter } from "react-router-dom";
import * as CryptoJS from "crypto-js";
import { getData, getDataWithKey } from "../../apis/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import WidgetInitializationError from "./WidgetInitializationError/WidgetInitializationError.component";
import { apiEndpoints } from "./../../apis/apiEndpoints";
import UpdateCardDetails from "./UpdateCardDetails/UpdateCardDetails.component";
import WidgetLoader from "./WidgetLoader/WidgetLoader.component";
import { ROUTES } from "./../../helpers/ROUTES";
import { localCurrency, WIDGET_RESPONSE_STATUSES } from "./utils";
import DashboardComponent from "./WidgetRevampv2/Dashboard/Dashboard.component";
import TopupComponent from "./WidgetRevampv2/Topup/Topup.component";
import ProfileComponent from "./WidgetRevampv2/Profile/Profile.component";
import ProfileDetails from "./WidgetRevampv2/Profile/components/ProfileDetails";
import CardInformation from "./WidgetRevampv2/Profile/components/CardInformation";
import TransactionPin from "./WidgetRevampv2/Profile/components/TransactionPin";
import TransactionsComponent from "./WidgetRevampv2/Transactions/Transactions.component";
import LoansComponent from "./WidgetRevampv2/Loans/Loans.component";
import LoanRequest from "./WidgetRevampv2/Loans/components/LoanRequests/LoanRequest";
import LoanHistory from "./WidgetRevampv2/Loans/components/LoanHistory/LoanHistory";
import LoanRepaymentAmount from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentAmount";
import LoanRepaymentMethods from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentMethods";
import LoanRepaymentViaExistingCard from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentViaExistingCard";
import LoanRepaymentViaWallet from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentViaWallet";
import LoanRepaymentViaNewCard from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentViaNewCard";
import WithdrawalComponent from "./WidgetRevampv2/Withdrawal/Withdrawal.component";
import SetPinComponent from "./WidgetRevampv2/SetPinComponent";
import { deviceType } from "react-device-detect";
import { RiCloseCircleFill } from "react-icons/all";
import StepOneComponent from "./Step1/StepOne.component";
import { appInsights } from "../AppInsight/AppInsight";
import CONFIG from "../../helpers/config";
import { widgetSettings } from "./WidgetRevampv2/mock";
import LoanRepaymentProof from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentProof";
import ContactUs from "./WidgetRevampv2/Dashboard/components/ContactUs";
import LoanRepayment from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepayment";
import LoanRepaymentViaMpesa from "./WidgetRevampv2/Loans/components/LoanRepayments/LoanRepaymentMpesa";

class WidgetFlow extends Component {
  state = {
    error: false,
    loan_application_referenceno: "",
    state_token: "",
    first_name: "",
    last_name: "",
    email: "",
    borrower_phone: "",
    principalAmount: "",
    loanTenor: "",
    loan_interest: "",
    loan_payback: "",
    repayment_cycle: "",
    public_key: "",
    loan_product: "",
    repayment_type: "",
    dataFromAggregator: {},
    aggregator_id: "",
    aggregator_domain: "",
    token: "",
    first_time_borrower: true,
    bvn_number: "",
    bank_code: "",
    bank_account_number: "",
    edit_widget: false,
    finishedFirstInitializations: false,
    showWidgetAnimation: false,
    initializationError: false,
    paystackOpened: false,
    bankStatementWidgetOpened: false,
    bankList: [],
    worldCountries: [],
    loanProducts: [
      {
        interest_rate: 0,
        maximum_amount: "",
        maximum_tenor: 0,
        product_name: "",
        repayment_type: "",
      },
    ],
    loan_offer_step: false,
    encryptedKey: "",
    loanInformation: {},
    customStyles: {
      buttonBackgroundColor: "#713FFF",
      buttonTextColor: "#fff",
      dropdownTextColor: "#000",
      dropdownBackgroundColor: "#fff",
      acceptButtonBackgroundColor: "#713FFF",
      acceptButtonTextColor: "#fff",
      declineButtonBackgroundColor: "#de4437",
      declineButtonTextColor: "#fff",
    },
    returningUserDetails: null,
    customer_category: "",
    customer_type: "",
    company_name: "",
    business_registration_number: "",
    country_code: "",
    currency: "",
    use_wallet: true,
    allow_contact_us: false,
    canCloseWidget: true,
    bank_statement_provider: null,
    bank_statement_provider_id: null,
    bank_statement_connected: false,
  };

  componentDidMount() {
    // Disable Scroll on this page
    document.querySelector("body").style.overflow = "hidden";
    Promise.all([
      this.listenForMessage(),
      this.getBankList(),
      this.getCountryCodes(),
    ]);
  }

  componentWillUnmount() {
    // Enable page Scroll when this page is unmounted
    document.querySelector("body").style.overflow = "visible";
  }

  updateState = (state) => {
    this.setState(state);
  };
  updatePayStackStatus = () => {
    this.setState({ paystackOpened: true });
  };
  updateBankStatementWidgetStatus = () => {
    this.setState({ bankStatementWidgetOpened: true });
  };

  setLoanInformation = (loan) => {
    this.setState({ loanInformation: loan });
  };

  // check for message coming from aggregator
  listenForMessage = () => {
    try {
      const params = new URL(document.location).searchParams;
      const message = params.get("message");
      let messageFromAggregator;

      if (message && typeof message === "string") {
        messageFromAggregator = JSON.parse(message);
        this.setInitialData(messageFromAggregator, document.location.origin);
      } else {
        window.addEventListener("message", (event) => {
          const messageFromAggregator = event.data;
          this.setInitialData(messageFromAggregator, event.origin);
        });
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "WidgetFlow.jsx" },
      });
      this.setState({
        error: true,
        finishedFirstInitializations: true,
        showWidgetAnimation: false,
        initializationError: true,
      });
    }
  };

  setInitialData = (messageFromAggregator, origin) => {
    if (
      messageFromAggregator &&
      Object.keys(messageFromAggregator)?.length > 0
    ) {
      if (
        Number.isSafeInteger(messageFromAggregator?.aggregator_id) === false
      ) {
        this.setState({
          error: true,
          finishedFirstInitializations: true,
          showWidgetAnimation: false,
          initializationError: true,
        });
        return;
      } else {
        const {
          customStyles,
          public_key,
          aggregator_id,
          aggregator_loan_ref,
          identity_number,
          bank_code,
          bank_account_number,
          customer_phone,
          email,
          first_name,
          last_name,
          customer_category,
          customer_type,
          company_name,
          business_registration_number,
          country_code,
          canCloseWidget,
        } = messageFromAggregator;
        if (!company_name || !first_name || !last_name || !customer_phone) {
          console.log(`sending error`);
          this.setState({
            error: true,
            finishedFirstInitializations: true,
            showWidgetAnimation: false,
            initializationError: true,
          });
          appInsights.trackException({
            exception:
              "Missing one of the following required fields : Company name, First Name, Last Name, Customer Phone",
            properties: { fileName: "WidgetFlow.jsx" },
          });
        }
        localStorage.setItem("customStyles", JSON.stringify(customStyles));
        messageFromAggregator.borrower_phone = customer_phone;
        this.setState({
          dataFromAggregator: messageFromAggregator,
          aggregator_id: aggregator_id,
          loan_application_referenceno: aggregator_loan_ref,
          bvn_number: identity_number,
          bank_code: bank_code,
          bank_account_number: bank_account_number,
          public_key: public_key,
          borrower_phone: customer_phone,
          first_name,
          last_name,
          email,
          customer_category,
          customer_type,
          company_name,
          business_registration_number,
          country_code,
          aggregator_domain: origin,
          canCloseWidget,
        });
        this.getGeneratedAggregatorKey(aggregator_id, public_key);
      }
    } else {
      this.setState({
        error: true,
        finishedFirstInitializations: true,
        showWidgetAnimation: false,
        initializationError: true,
      });
    }
  };

  // create encrypted key
  getGeneratedAggregatorKey = async (aggregator_id, public_key) => {
    try {
      const response = await getData(
        `${apiEndpoints.getAggregatorKey}?aggregator_id=${aggregator_id}&public_key=${public_key}`
      );
      this.getEncryptedKey(public_key, response.data.private_key);
      this.setState({ edit_widget: response.data.allow_widget_edit });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "WidgetFlow.jsx" },
      });
      this.setState({
        error: errorHandler(error),
        initializationError: true,
      });
    }
  };

  getEncryptedKey = (public_key, private_key) => {
    const message = CryptoJS.enc.Utf8.parse(public_key);
    const secretkey = CryptoJS.enc.Utf8.parse(private_key);

    const aesOptions = { mode: CryptoJS.mode.CBC, iv: secretkey };
    const encryptedMessage = CryptoJS.AES.encrypt(
      message,
      secretkey,
      aesOptions
    );
    const encryptedKey = encryptedMessage.toString();
    const { aggregator_id, bvn_number, aggregator_domain } = this.state;
    this.setState({ encryptedKey });

    Promise.all([
      this.getWidgetSettings(encryptedKey),
      this.checkFirstTimeBorrower(aggregator_id, bvn_number, encryptedKey),
      this.getAllLoanProducts(aggregator_id, aggregator_domain, encryptedKey),
    ]).then(() => {
      this.setState({
        finishedFirstInitializations: true,
        showWidgetAnimation: true,
      });
    });

    setTimeout(() => {
      this.setState({
        showWidgetAnimation: false,
      });
    }, 5000);
  };

  getBankList = async () => {
    try {
      const response = await getData("/account/signed_banks/");
      let banks = response.data;
      banks.sort(function (a, b) {
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
          return -1;
        }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.setState({ bankList: banks });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "WidgetFlow.jsx" },
      });
      this.setState({
        error: errorHandler(error),
        initializationError: true,
      });
    }
  };

  getCountryCodes = async () => {
    try {
      const response = await getData("/account/advancly_specific_countries");
      this.setState({ worldCountries: response.data });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "WidgetFlow.jsx" },
      });
      this.setState({
        error: errorHandler(error),
        initializationError: true,
      });
    }
  };

  getWidgetSettings = async () => {
    console.log({ key: this.state.encryptedKey });
    try {
      let res;
      if (CONFIG.USE_MOCK) {
        res = widgetSettings.data;
      } else {
        res = await getDataWithKey(
          `${apiEndpoints.getWidgetSettings}?AggregatorId=${this.state.aggregator_id}&CountryCode=${this.state.country_code}`,
          this.state.encryptedKey
        );
      }
      console.log({ res: res.data });
      this.setState({
        use_wallet: res.data[0]?.setting_value === "1",
        allow_contact_us: res.data[1]?.setting_value === "1",
      });
    } catch (error) {
      console.log({ error });
      appInsights.trackException({
        exception: error,
        properties: { fileName: "WidgetFlow.jsx" },
      });
      this.setState({
        error: true,
        finishedFirstInitializations: true,
        showWidgetAnimation: false,
        initializationError: true,
      });
    }
  };

  checkBankStatementAuthStatus = async (data) => {
    try {
      const res = await getDataWithKey(
        `${apiEndpoints.checkBankProviderLinked}?aggregator_id=${this.state.aggregator_id}&bank_statement_provider_id=${data.bank_statement_provider_id}&customer_id=${data.customer_id}`,
        this.state.encryptedKey
      );
      this.setState({
        bank_statement_connected: res?.status,
      });
      this.props.history.push(ROUTES.widgetDashboard);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "WidgetFlow.jsx" },
      });
      console.log(error);
      this.setState({
        error: errorHandler(error),
        initializationError: true,
      });
    }
  };

  // check for first time borrowers
  checkFirstTimeBorrower = async () => {
    try {
      const res = await getDataWithKey(
        `${apiEndpoints.checkCustomerExist}?business_registration_number=${this.state.business_registration_number}&identity_number=${this.state.bvn_number}&phone_number=${this.state.borrower_phone}&aggregator_id=${this.state.aggregator_id}&email=${this.state.email}`,
        this.state.encryptedKey
      );
      let currency = localCurrency(this.state.country_code);
      if (res?.message?.toLowerCase() === "match found") {
        res.data.customer_category = this.state.customer_category;
        res.data.aggregator_id = this.state.aggregator_id;
        res.data.encryptedKey = this.state.encryptedKey;
        this.setState({
          first_time_borrower: false,
          // edit_widget: false,
          returningUserDetails: res.data,
          currency: currency,
          customer_type: res.data?.customer_type,
          first_name: res.data?.first_name,
          last_name: res.data?.last_name,
          bvn_number: res.data?.bvn_number,
          business_registration_number: res.data?.business_registration_number,
          borrower_phone: res.data?.borrower_phone,
          company_name: res.data?.company_name,
          email: res.data?.email,
          bank_statement_provider: res.data?.bank_statement_provider,
          bank_statement_provider_id: res.data?.bank_statement_provider_id,
        });
        // This will make the borrower see application page (page 2) straight away if he isnt a first time borrower
        await this.checkBankStatementAuthStatus({
          customer_id: res.data?.customer_id,
          bank_statement_provider_id: res.data?.bank_statement_provider_id,
        });
        // this.props.history.push(ROUTES.widgetDashboard);
      } else {
        this.setState({ first_time_borrower: true });
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "WidgetFlow.jsx" },
      });
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.message?.includes("No match found")
      ) {
        this.setState({ first_time_borrower: true });
      } else {
        this.setState({
          error: errorHandler(error),
        });
      }
    }
  };

  getAllLoanProducts = async (aggr_id, agg_domain, encryptedKey) => {
    try {
      const response = await getDataWithKey(
        `/misc/query_product_key/?aggregator_id=${aggr_id}&country_code=${this.state.country_code}&aggregator_domain=${agg_domain}`,
        encryptedKey
      );
      this.setState({ loanProducts: response.data });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "WidgetFlow.jsx" },
      });
      this.setState({
        error: errorHandler(error),
      });
    }
  };

  scrollToTop = () => {
    const widgetPageTop = document.getElementById("widget-page-top");
    widgetPageTop?.scrollIntoView({
      behavior: "smooth",
    });
  };

  sendResponseToClient = ({ status, data }) => {
    const response = {
      status,
      data,
    };
    // Send to web
    window?.top?.postMessage(response, "*");
    // Send to React Native App
    window?.ReactNativeWebView?.postMessage(JSON.stringify(response), "*");
  };

  render() {
    console.log("encryptionKeyFromUp: ", this.state.returningUserDetails);
    return (
      <Fragment>
        <div id={deviceType} className={styles.widgetMainWrapper}>
          <div className={styles.widgetWrapper}>
            {this.state.canCloseWidget ? (
              <div
                className={
                  deviceType === "mobile"
                    ? styles.cancelIconMobile
                    : styles.cancelIcon
                }
                onClick={() => {
                  this.sendResponseToClient({
                    status: WIDGET_RESPONSE_STATUSES.cancel,
                    data: { message: "User cancelled" },
                  });
                }}
              >
                <RiCloseCircleFill
                  size={deviceType === "mobile" ? "5rem" : "2rem"}
                  color="#fff"
                />
              </div>
            ) : null}
            {this.state.initializationError &&
            !this.state.paystackOpened &&
            !this.state.bankStatementWidgetOpened ? (
              <WidgetInitializationError />
            ) : !this.state.finishedFirstInitializations ||
              this.state.showWidgetAnimation ? (
              <WidgetLoader
                showWidgetAnimation={this.state.showWidgetAnimation}
              />
            ) : (
              <div
                className={
                  deviceType === "mobile" ? styles.widgetMobile : styles.widget
                }
              >
                <div id="widget-page-top"></div>
                {this.state.error &&
                  !this.state.paystackOpened &&
                  !this.state.bankStatementWidgetOpened && (
                    <p className="alert alert-danger w-100">
                      {this.state.error}
                    </p>
                  )}
                <div className="w-100">
                  <Route
                    path={ROUTES.widget}
                    exact
                    render={() => (
                      <StepOneComponent
                        updateState={this.updateState}
                        dataFromAggregator={this.state.dataFromAggregator}
                        first_time_borrower={this.state.first_time_borrower}
                        edit_widget={this.state.edit_widget}
                        scrollToTop={this.scrollToTop}
                        checkFirstTimeBorrower={this.checkFirstTimeBorrower}
                        deviceType={deviceType}
                        worldCountries={this.state.worldCountries}
                        handleOnBoardingSuccess={(onboardingResponse) => {
                          this.sendResponseToClient({
                            status: WIDGET_RESPONSE_STATUSES.success,
                            data: { message: onboardingResponse },
                          });
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetDashboard}
                    render={() => (
                      <DashboardComponent
                        wallet={this.state.returningUserDetails}
                        first_name={this.state.first_name}
                        customer_type={this.state.customer_type}
                        company_name={this.state.company_name}
                        deviceType={deviceType}
                        currency={this.state.currency}
                        useWallet={this.state.use_wallet}
                        allowContactUs={this.state.allow_contact_us}
                        updateBankStatementWidgetStatus={
                          this.updateBankStatementWidgetStatus
                        }
                        bank_statement_provider={
                          this.state.bank_statement_provider
                        }
                        bank_statement_connected={
                          this.state.bank_statement_connected
                        }
                        updateBankStatementStatus={() => {
                          this.setState({
                            bank_statement_connected: true,
                          });
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetProfile}
                    render={() => (
                      <ProfileComponent
                        first_name={this.state.first_name}
                        last_name={this.state.last_name}
                        email={this.state.email}
                        wallet={this.state.returningUserDetails}
                        encryptedKey={this.state.encryptedKey}
                        aggregator_id={this.state.aggregator_id}
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetProfileInfo}
                    render={() => (
                      <ProfileDetails
                        borrower_phone={this.state.borrower_phone}
                        email={this.state.email}
                        first_name={this.state.first_name}
                        last_name={this.state.last_name}
                        customer_type={this.state.customer_type}
                        company_name={this.state.company_name}
                        bvn={this.state.bvn_number}
                        business_registration_number={
                          this.state.business_registration_number
                        }
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetTopUp}
                    render={() => (
                      <TopupComponent
                        wallet={this.state.returningUserDetails}
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetCard}
                    render={() => (
                      <CardInformation
                        wallet={this.state.returningUserDetails}
                        email={this.state.email}
                        updatePayStackStatus={this.updatePayStackStatus}
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetPin}
                    render={() => (
                      <TransactionPin
                        wallet={this.state.returningUserDetails}
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetTransactions}
                    render={() => (
                      <TransactionsComponent
                        wallet={this.state.returningUserDetails}
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetWithdrawal}
                    render={() => (
                      <WithdrawalComponent
                        bankList={this.state.bankList}
                        wallet={this.state.returningUserDetails}
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetSetPin}
                    component={SetPinComponent}
                    currency={this.state.currency}
                  />
                  <Route
                    path={ROUTES.widgetUpdateCard}
                    exact
                    render={() => (
                      <UpdateCardDetails
                        dataFromAggregator={this.state.dataFromAggregator}
                        encryptedKey={this.state.encryptedKey}
                        customStyles={this.state.customStyles}
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetLoan}
                    component={LoansComponent}
                    currency={this.state.currency}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetLoanRequest}
                    render={() => (
                      <LoanRequest
                        wallet={this.state.returningUserDetails}
                        deviceType={deviceType}
                        currency={this.state.currency}
                        useWallet={this.state.use_wallet}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetLoanHistory}
                    render={() => (
                      <LoanHistory
                        wallet={this.state.returningUserDetails}
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetLoanRepaymentAmount}
                    component={LoanRepaymentAmount}
                    currency={this.state.currency}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetLoanRepaymentOptionView}
                    component={LoanRepayment}
                    currency={this.state.currency}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetLoanRepaymentMpesa}
                    render={() => (
                      <LoanRepaymentViaMpesa
                        currency={this.state.currency}
                        encryptedKey={this.state.returningUserDetails}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetLoanRepaymentMethods}
                    render={() => (
                      <LoanRepaymentMethods
                        wallet={this.state.returningUserDetails}
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetLoanRepaymentViaExistingCard}
                    component={LoanRepaymentViaExistingCard}
                    currency={this.state.currency}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetLoanRepaymentViaWallet}
                    render={() => (
                      <LoanRepaymentViaWallet
                        wallet={this.state.returningUserDetails}
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetLoanRepaymentViaNewCard}
                    component={LoanRepaymentViaNewCard}
                    currency={this.state.currency}
                  />

                  <Route
                    exact
                    path={ROUTES.widgetLoanRepaymentProof}
                    render={() => (
                      <LoanRepaymentProof
                        encryptedKey={
                          this.state.returningUserDetails.encryptedKey
                        }
                        deviceType={deviceType}
                        currency={this.state.currency}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.widgetContactUs}
                    render={() => (
                      <ContactUs
                        encryptedKey={
                          this.state.returningUserDetails.encryptedKey
                        }
                        deviceType={deviceType}
                        borrower_phone={this.state.borrower_phone}
                        email={this.state.email}
                        first_name={this.state.first_name}
                        customer_id={
                          this.state.returningUserDetails.customer_id
                        }
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(WidgetFlow);
