import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../helpers/ROUTES";
import ButtonLoader from "./ButtonLoader/ButtonLoader.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInputField from "./CustomHTMLElements/CustomInputField";
import { yupValidators } from "../helpers/yupValidators";
import { postData } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";
import { errorHandler } from "../helpers/errorHandler";

const schema = yup.object().shape({
  phoneNumber: yupValidators.phoneNumber,
  password: yup.string().required("Please enter your password"),
});

const Login = (props) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setError(false);
    const { phoneNumber, password } = data;

    const reqBody = {
      username: phoneNumber,
      password,
    };

    try {
      const response = await postData(apiEndpoints.loginUrl, reqBody);
      setLoading(false);

      if (response.user_type === "borrower") {
        localStorage.setItem("token", response.sso_auth_token);
        localStorage.setItem("sso_auth_token", response.sso_auth_token);
        localStorage.setItem(
          "forceChangeOfPassword",
          response.forceChangePassword
        );
        if (response.forceChangePassword) {
          setSuccess(
            "Your login is succesful. You will be required to change your password on the next page, please do."
          );
          setTimeout(() => {
            props.history.push(ROUTES.changePassword);
          }, 5000);
        } else {
          props.history.push("/");
        }
      } else {
        setError("This account is not an aggregator account");
      }
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
    }
  };
  return (
    <div role="main" className="container space-2">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-5">
          <p className="h3 text-primary text-center mb-6">Login as borrower</p>
          {error && <p className="alert alert-danger mb-4 small">{error}</p>}
          {success && (
            <p className="alert alert-danger mb-4 small">{success}</p>
          )}
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <CustomInputField
              type="number"
              maxLength="20"
              reference={register}
              name="phoneNumber"
              placeholder="08012345678"
              label="Phone Number"
              errors={errors.phoneNumber}
            />
            <CustomInputField
              type="password"
              maxLength="128"
              reference={register}
              name="password"
              placeholder="Password"
              label="Password"
              errors={errors.password}
              hasActionButton={true}
            />
            <button
              type="submit"
              value="submit"
              className="btn btn-sm btn-primary btn-block mt-4"
              disabled={loading}
            >
              {loading ? <ButtonLoader /> : "Login"}
            </button>
            <Link to="/create-account">
              <button
                type="button"
                value="button"
                className="btn btn-sm btn-secondary btn-block mt-4"
              >
                Create account
              </button>
            </Link>
          </form>
          <p className="text-right  mt-3">
            Forgot Password?{" "}
            <Link to={ROUTES.resetPassword}>Click here to reset it.</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
