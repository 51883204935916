import React, { Fragment, useRef, useState } from "react";
import { useHistory } from "react-router";
import CustomWidgetButton from "./../../CustomWidgetElements/CustomWidgetButton.component";
import { PaystackConsumer } from "react-paystack";
import { paystackConfig } from "../../../helpers/thirdPartyConfig";
import { putDotNetDataWithKey } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "./../../../helpers/errorHandler";
import { ReactComponent as SuccessIcon } from "../../../assets/success-icon.svg";
import AdvanclyLogo from "../../../assets/advancly-logo-white.png";
import VisaIcon from "../../../assets/visa.svg";
import MastercardIcon from "../../../assets/mastercard.svg";
import VerveIcon from "../../../assets/verve.svg";
import useSavedPaystackDetails from "./../../../custom-hooks/useSavedPaystackDetails";
import styles from "./UpdateCardDetails.module.scss";
import { useQueryCache } from "react-query";
import WidgetInnerPageLoader from "../WidgetInnerPageLoader/WidgetInnerPageLoader.component";
import WidgetCustomTitle from "../WidgetCustomTitle/WidgetCustomTitle.component";
import WidgetBackButton from "./../WidgetBackButton/WidgetBackButton.component";

export default function UpdateCardDetails({
  dataFromAggregator,
  encryptedKey,
  customStyles,
}) {
  const history = useHistory();
  const paystackRef = useRef();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const queryCache = useQueryCache();

  const { data, isLoading } = useSavedPaystackDetails({
    aggregator_id: dataFromAggregator?.aggregator_id,
    bvn_number: dataFromAggregator?.bvn_number,
    encryptedKey,
  });

  const openPaystackWidget = () => {
    paystackRef.current.click();
  };

  const onPaystackSuccess = async ({ reference, trans }) => {
    // Make backend call here
    try {
      setError("");
      setSuccess("");
      setLoading(true);

      const reqBody = {
        aggregator_id: dataFromAggregator?.aggregator_id,
        bvn_number: dataFromAggregator?.bvn_number,
        paystack_reference: reference,
        paystack_transaction: trans,
        reason: "",
      };

      await putDotNetDataWithKey(
        apiEndpoints.paystackInfo,
        reqBody,
        encryptedKey
      );
      setLoading(false);
      setSuccess("Card Updated");
      queryCache.invalidateQueries([
        {
          aggregator_id: dataFromAggregator?.aggregator_id,
          bvn_number: dataFromAggregator?.bvn_number,
          encryptedKey,
        },
        "getSavedPaystackDetails",
      ]);
    } catch (error) {
      setError(errorHandler(error));
      setLoading(false);
    }
  };
  const onPaystackClose = () => {};

  const paystackComponentProps = {
    ...paystackConfig({
      email: dataFromAggregator.email,
      amount: 5000, // 50 naira is 5000 kobo
    }),
    onSuccess: (reference) => {
      onPaystackSuccess(reference);
    },
    onClose: onPaystackClose,
  };

  const cardImage = () => {
    const cardType = data?.card_type.toLowerCase().trim();
    return cardType?.includes("mastercard") ? (
      <img src={MastercardIcon} alt="logo" />
    ) : cardType?.includes("visa") ? (
      <img src={VisaIcon} alt="logo" />
    ) : cardType?.includes("verve") ? (
      <img src={VerveIcon} alt="logo" />
    ) : null;
  };

  if (loading || isLoading) {
    return <WidgetInnerPageLoader />;
  }

  return (
    <div>
      {success ? (
        <div className="d-flex justify-content-center align-items-center text-center flex-column w-100">
          <img
            src={AdvanclyLogo}
            alt="Powered by Advancly"
            height="25"
            className="mb-5"
          />
          <div className="mt-5">
            <SuccessIcon />
            <div className="mt-4 mb-3 color-white font-weight-bold">
              {success}
            </div>
          </div>
          <div className="mt-5">
            <CustomWidgetButton
              customStyles={customStyles}
              value="Done"
              type="button"
              onClick={() => history.goBack()}
              loading={loading}
              disabled={loading}
            />
          </div>
        </div>
      ) : (
        <Fragment>
          {error && (
            <p className="alert alert-danger small mb-4">
              {errorHandler(error)}
            </p>
          )}
          <WidgetCustomTitle title="Card details" />

          <div className="card">
            <div className="card-body p-2">
              {data?.last_four_digits ? (
                <span className={styles.alignTextAndLogo}>
                  {cardImage()}
                  ****{data?.last_four_digits}
                </span>
              ) : (
                <div className="text-center font-weight-bold text-danger w-100">
                  No Saved Card
                </div>
              )}
            </div>
          </div>

          <div className="d-flex mt-5 justify-content-end align-items-center">
            <WidgetBackButton text="Close" />
            <CustomWidgetButton
              customStyles={customStyles}
              value={!data?.last_four_digits ? "Add Card" : "Update Card"}
              type="button"
              onClick={openPaystackWidget}
              loading={loading}
              disabled={loading || isLoading}
            />
          </div>

          <PaystackConsumer {...paystackComponentProps}>
            {({ initializePayment }) => (
              <button
                ref={paystackRef}
                onClick={() =>
                  initializePayment(onPaystackSuccess, onPaystackClose)
                }
                className="d-none"
              />
            )}
          </PaystackConsumer>
        </Fragment>
      )}
    </div>
  );
}
