import React from "react";

const DeploymentStatus = () => {
  return (
    <div>
      <p className="text-center font-weight-bold">
        Deployment Version : - 23/03/2023:7:55PM
      </p>
    </div>
  );
};

export default DeploymentStatus;
