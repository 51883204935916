export const formatCurrency = (number: number) => {
  return Number(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const formatMoney = (amount: number, currency: string) => {
  if (!amount) return currency + " " + "0.00";
  return currency + " " + amount.toLocaleString();
};
