import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/all";
import { RepaymentScheduleProp } from "./LoanRepaymentSchedule";
import repayment from "../../../../../../assets/loans/repayment.svg";
import moment from "moment";
import { formatMoney } from "../../../../../../helpers/formatCurrency";
import { getDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import Loader from "../../../../../Loader/Loader";
import { appInsights } from "../../../../../AppInsight/AppInsight";

const LoanRepayments = ({
  onCancel,
  encryptedKey,
  loan_ref,
  aggregator_id,
  currency,
}: RepaymentScheduleProp) => {
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [isLoading, setIsLoading] = useState(false);
  const [repaymentDetails, setRepaymentDetails] = useState([]);

  useEffect(() => {
    appInsights.trackPageView({ name: "LoanRepayments.tsx", isLoggedIn: true });
  }, []);
  async function fetchRepayments() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getRepayments}?loan_ref=${loan_ref}&aggregator_id=${aggregator_id}`,
        encryptedKey
      );
      setRepaymentDetails(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepayments },
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchRepayments();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div onClick={onCancel} className="cursor">
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Repayments</h4>
      <br />
      {repaymentDetails?.length ? (
        <Fragment>
          {repaymentDetails?.map((data, index) => {
            const { repayment_date, amount_paid } = data;
            return (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <img
                      src={repayment}
                      alt=""
                      className="img-fluid mr-2"
                      style={{
                        width: "40px",
                        height: "40px",
                        objectFit: "contain",
                      }}
                    />
                    <div>
                      <p className="padding-none">
                        {formatMoney(amount_paid || 0, currency)}
                      </p>{" "}
                      <p
                        className={
                          data?.repayment_status === "success"
                            ? "padding-none text-capitalize text-success"
                            : "padding-none text-capitalize text-danger"
                        }
                      >
                        {data?.repayment_status}
                      </p>
                      <p className="padding-none text-muted">
                        {moment(repayment_date).format("D/MM/YYYY, h:mm a")}
                      </p>
                    </div>
                  </div>
                </div>
                <hr />
              </>
            );
          })}
        </Fragment>
      ) : (
        <h5 className="text-center">No Data Available</h5>
      )}
    </div>
  );
};

export default LoanRepayments;
