import React from "react";
import styles from "./WidgetLoader.module.scss";
import ButtonLoader from "./../../ButtonLoader/ButtonLoader.component";
import AdvanclyLogo from "../../../assets/advancly-logo-full.png";

export default function WidgetLoader({ showWidgetAnimation }) {
  return (
    <div className={styles.widgetLoader}>
      {showWidgetAnimation ? (
        <img src={AdvanclyLogo} alt="Powered by Advancly" height="30" />
      ) : (
        <ButtonLoader type="lg" noMarginLeft={true} />
      )}
    </div>
  );
}
